/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import { Canvas, StaticCanvas, FabricObject } from 'fabric';

import { prepareHeaders, retryFetch } from '@app/app/lib/api';

import { serverPath } from '@app/utils/server-path';

import { analytics } from '@app/app/analytics';

import { Layer, LayerObject } from '../types/types';
import { loadObjectsWithLayers } from '../utils/helpers';
import { copyObjects, pasteObjects } from '../utils/copyAndPasteObjects';
import { extractSlideMetadata } from '../utils';

import { CANVAS_DIMENSIONS, CANVAS_SCALE } from '../config/constants';

import { restoreOriginalOpacitiesInState } from '../utils/opacity';

import { useLoggingStore } from './logger';

type BackupState = any[];

type LayersMap = { [key in Layer]: LayerObject[] };

type MetaData = Record<string, any>;

// Object movement state
interface MovementState {
  lastDirection: string | null;
  hasPendingChanges: boolean;
  activeObjects: string[]; // IDs of active objects
}

export type CanvasData = {
  initialData: any;
  fabricInstance: Canvas;
  element: HTMLElement;
  backups: BackupState;
  backupDepth: number;
  currentStateIndex: number;
  layersMap: { [key in Layer]: LayerObject[] };
  metaData?: MetaData;
  canvasDimensions?: { width: number; height: number };
  canvasScale?: number;
  savedLastVersionStatus: {
    isSaved: boolean;
    isSaving: boolean;
    error: string | null;
  };
  staticCanvas?: {
    instance: StaticCanvas;
    containerElement: HTMLElement;
  };
  // Object movement state
  movementState: MovementState;
};

// TODO: Must be a map
export type CanvasDataList = {
  [key: string]: CanvasData;
};

type CanvasStoreState = {
  saveBackupTimers: Map<string, NodeJS.Timeout>;
  workspaceId?: string;
  activeCanvasId: string | null;
  canvasData: CanvasDataList;
  buffer: {
    objects: any[]; // List of copied objects
    sourceCanvasId: string | null; // ID of the canvas from which the objects were copied
  };
  isUndoOrRedoInProgress: boolean;
};

type CanvasActions = {
  setActiveCanvasId: (canvasId: string) => void;
  addCanvas: ({
    canvasId,
    initialData,
    fabricInstance,
    element,
    canvasScale,
    canvasDimensions,
  }: {
    canvasId: string;
    initialData: any;
    fabricInstance: Canvas;
    element: HTMLElement;
    backupDepth?: number;
    metaData?: MetaData;
    canvasScale?: number;
    canvasDimensions?: { width: number; height: number };
  }) => void;
  deleteCanvas: (canvasId: string) => void;
  getCanvasState: (canvasId: string) => CanvasData | null;
  getFabricInstance: (canvasId: string) => any | null;
  addBackup: (canvasId: string, state: any, sendToServer?: boolean) => void;
  undo: (canvasId: string) => any | null;
  redo: (canvasId: string) => any | null;
  addLayerObject: (canvasId: string, layer: Layer, object: LayerObject) => void;
  removeLayerObject: (canvasId: string, layer: Layer, object: LayerObject) => void;
  clearLayer: (canvasId: string, layer: Layer) => void;
  updateMetaData: (canvasId: string, meta: MetaData) => void;
  getMetaData: (canvasId: string) => Record<string, any> | null;
  copyToBuffer: (canvasId: string) => Promise<void>;
  pasteFromBuffer: (
    targetCanvasId: string,
    options?: {
      insertCoordinates?: { left: number; top: number };
      offsetX?: number;
      offsetY?: number;
      isNeedSetActivePastedObject?: boolean; // Activate pasted objects after pasting (true by default)
    },
  ) => Promise<void>;
  closeSelectionFrame: () => void;
  clearStore: () => void;
  saveBackupToServer: (canvasId: string, state: any) => void;
  setWorkspaceId: (workspaceId: string) => void;
  removeCanvas: (canvasId: string) => void;
  updateCanvasScale: (canvasId: string, scale: number) => void;
  registerStaticCanvas: (
    canvasId: string,
    instance: StaticCanvas,
    containerElement: HTMLElement,
  ) => void;
  unregisterStaticCanvas: (canvasId: string) => void;
  updateStaticCanvas: (canvasId: string, canvasState: any) => void;
  updateCanvasDimensions: (canvasId: string, dimensions: { width: number; height: number }) => void;
  // New methods for object movement management
  updateMovementState: (canvasId: string, objects: FabricObject[], direction: string) => boolean;
  finishObjectMovement: (canvasId: string, forceBackup?: boolean) => boolean;
  checkActiveObjectsChanged: (canvasId: string) => boolean;
};

type CanvasStore = CanvasActions & CanvasStoreState;

const defaultState: CanvasStoreState = {
  saveBackupTimers: new Map(),
  activeCanvasId: null,
  canvasData: {},
  buffer: {
    objects: [],
    sourceCanvasId: null,
  },
  isUndoOrRedoInProgress: false,
};

const delayForSaveBackupToServer = 5000;

export const useCanvasStore = create<CanvasStore>((set, get) => ({
  ...defaultState,

  saveBackupToServer: (canvasId, state) => {
    const { saveBackupTimers } = get();
    if (saveBackupTimers.has(canvasId)) {
      clearTimeout(saveBackupTimers.get(canvasId));
    }
    const timer = setTimeout(async () => {
      try {
        const data = await retryFetch({
          cb: async () =>
            fetch(`${serverPath.workspace.saveSlideConfig}${get().workspaceId}/${canvasId}/`, {
              method: 'POST',
              headers: prepareHeaders(),
              body: JSON.stringify(state),
            }).then((res) => {
              if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
              return res.json();
            }),
          retries: 3,
          delayBetween: 550,
        });
        console.debug(`✅ Backup saved for canvas: ${canvasId}`, data);

        //Analytics. Send event if more 3 backups
        if (get().workspaceId !== undefined && get().canvasData[canvasId]?.backups?.length > 3) {
          analytics.emitEvent('design_editing', {
            GTM: {
              editing_location: 'workspace',
              editable_element: 'canvas',
              processed_file_id: +get().workspaceId!,
              flow: 'workspace',
            },
          });
        }

        set((currentState) => ({
          canvasData: {
            ...currentState.canvasData,
            [canvasId]: {
              ...currentState.canvasData[canvasId],
              savedLastVersionStatus: {
                isSaved: true,
                isSaving: false,
                error: null,
              },
            },
          },
        }));
      } catch (error) {
        console.error(`⛔ Error saving backup for canvas ${canvasId}:`, error);
        set((currentState) => ({
          canvasData: {
            ...currentState.canvasData,
            [canvasId]: {
              ...currentState.canvasData[canvasId],
              savedLastVersionStatus: {
                isSaved: false,
                isSaving: false,
                error: 'Error sync',
              },
            },
          },
        }));
      }
      get().saveBackupTimers.delete(canvasId);
    }, delayForSaveBackupToServer);

    set((state) => {
      const newTimers = new Map(state.saveBackupTimers);
      newTimers.set(canvasId, timer);
      return { saveBackupTimers: newTimers };
    });
  },

  setActiveCanvasId: (canvasId) => {
    set({ activeCanvasId: canvasId });
  },

  setWorkspaceId: (workspaceId) => {
    set({ workspaceId });
  },

  removeCanvas: (canvasId) => {
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [canvasId]: _, ...remainingCanvasData } = state.canvasData;
      return { canvasData: remainingCanvasData };
    });
    if (get().activeCanvasId === canvasId) {
      set({ activeCanvasId: null });
    }
  },

  addCanvas: ({
    canvasId,
    initialData,
    fabricInstance,
    element,
    backupDepth = 1500,
    metaData = {},
    canvasScale = CANVAS_SCALE,
    canvasDimensions = CANVAS_DIMENSIONS,
  }: {
    canvasId: string;
    initialData: any;
    fabricInstance: Canvas;
    element: HTMLElement;
    backupDepth?: number;
    metaData?: MetaData;
    canvasScale?: number;
    canvasDimensions?: { width: number; height: number };
  }) => {
    const { loggingEnabled } = useLoggingStore.getState();
    if (loggingEnabled['CanvasWidget.addCanvas']) {
      console.debug(`✅ Canvas added: ${canvasId}`);
    }
    set((state) => ({
      canvasData: {
        ...state.canvasData,
        [canvasId]: {
          initialData,
          fabricInstance,
          element,
          backups: [],
          currentStateIndex: 0,
          backupDepth,
          layersMap: {
            // Init layersMap for every canvas
            background: [],
            middle: [],
            foreground: [],
            overlay: [],
          } as LayersMap,
          metaData,
          canvasScale,
          canvasDimensions,
          savedLastVersionStatus: {
            isSaved: true,
            isSaving: false,
            error: null,
          },
          // Initialize movement state
          movementState: {
            lastDirection: null,
            hasPendingChanges: false,
            activeObjects: [],
          },
        },
      },
    }));

    if (!get().activeCanvasId) {
      console.debug('set activeCanvasId ->', canvasId);
      get().setActiveCanvasId(canvasId);
    }
  },

  updateMetaData: (canvasId, meta: MetaData) => {
    set((state) => {
      const canvas = state.canvasData[canvasId];
      if (!canvas) return state;
      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...canvas,
            metaData: { ...canvas.metaData, ...meta },
          },
        },
      };
    });
  },

  getMetaData: (canvasId) => {
    return get().canvasData[canvasId]?.metaData || null;
  },

  deleteCanvas: (canvasId) => {
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [canvasId]: _, ...remainingCanvasData } = state.canvasData;
      return { canvasData: remainingCanvasData };
    });

    if (get().activeCanvasId === canvasId) {
      set({ activeCanvasId: null });
    }
  },

  getCanvasState: (canvasId) => {
    return get().canvasData[canvasId] || null;
  },

  getFabricInstance: (canvasId) => {
    return get().canvasData[canvasId]?.fabricInstance || null;
  },

  addBackup: (canvasId, state, sendToServer = true) => {
    set((currentState) => {
      const { loggingEnabled } = useLoggingStore.getState();

      const canvas = currentState.canvasData[canvasId];
      if (!canvas) return currentState;

      // Restore original opacity values before saving to backup
      const stateWithOriginalOpacities = restoreOriginalOpacitiesInState(state);

      const truncatedBackups = canvas.backups.slice(0, canvas.currentStateIndex + 1);
      const newBackups = [...truncatedBackups, stateWithOriginalOpacities];
      const limitedBackups = newBackups.slice(-canvas.backupDepth);

      if (loggingEnabled['addBackup']) {
        console.debug(`➕🗳️ Backups added for: ${canvasId}:`, stateWithOriginalOpacities);
      }

      return {
        canvasData: {
          ...currentState.canvasData,
          [canvasId]: {
            ...canvas,
            backups: limitedBackups,
            currentStateIndex: limitedBackups.length - 1,
            savedLastVersionStatus: {
              // Mark that the latest version is not saved to backend
              isSaved: false,
              isSaving: sendToServer ? true : false,
              error: null,
            },
          },
        },
      };
    });

    // Save backup to server
    if (sendToServer) {
      get().saveBackupToServer(canvasId, state);
    }

    // Update static canvas with the new state
    get().updateStaticCanvas(canvasId, state);
  },

  undo: async (canvasId) => {
    const { loggingEnabled } = useLoggingStore.getState();
    const canvas = get().canvasData[canvasId];
    if (!canvas || canvas.currentStateIndex === 0 || get().isUndoOrRedoInProgress) return null;

    set({ isUndoOrRedoInProgress: true });

    const newIndex = canvas.currentStateIndex - 1;
    const lastState = canvas.backups[newIndex];

    set((currentState) => ({
      canvasData: {
        ...currentState.canvasData,
        [canvasId]: {
          ...canvas,
          currentStateIndex: newIndex,
          savedLastVersionStatus: {
            // Mark that the latest version is not saved to backend
            isSaved: false,
            isSaving: true,
            error: null,
          },
        },
      },
    }));

    const fabricInstance = canvas.fabricInstance;
    if (fabricInstance) {
      if (loggingEnabled['CanvasWidget.undo']) {
        console.debug(`🔄 Undo loading state:`, lastState);
      }
      try {
        await loadObjectsWithLayers(fabricInstance, canvasId, lastState);
        fabricInstance.renderAll();
        if (loggingEnabled['CanvasWidget.undo']) {
          console.debug(`👈 Undo state loaded:`, lastState);
        }
      } catch (err) {
        if (loggingEnabled['CanvasWidget.undo']) {
          console.error(`⛔ Error while loading state:`, err);
        }
      }
    }

    get().saveBackupToServer(canvasId, lastState);

    // Update static canvas with the new state
    get().updateStaticCanvas(canvasId, lastState);

    set({ isUndoOrRedoInProgress: false });

    return lastState;
  },

  redo: async (canvasId) => {
    const { loggingEnabled } = useLoggingStore.getState();
    const canvas = get().canvasData[canvasId];
    if (
      !canvas ||
      canvas.currentStateIndex >= canvas.backups.length - 1 ||
      get().isUndoOrRedoInProgress
    ) {
      return null;
    }

    set({ isUndoOrRedoInProgress: true });

    const newIndex = canvas.currentStateIndex + 1;
    const nextState = canvas.backups[newIndex];

    set((currentState) => ({
      canvasData: {
        ...currentState.canvasData,
        [canvasId]: {
          ...canvas,
          currentStateIndex: newIndex,
          savedLastVersionStatus: {
            isSaved: false,
            isSaving: true,
            error: null,
          },
        },
      },
    }));

    const fabricInstance = canvas.fabricInstance;
    if (fabricInstance) {
      if (loggingEnabled['redo']) {
        console.debug(`🔄 Undo loading state:`, nextState);
      }

      try {
        await loadObjectsWithLayers(fabricInstance, canvasId, nextState);
        fabricInstance.renderAll();
        if (loggingEnabled['redo']) {
          console.debug('👉 Redo:', nextState);
        }
      } catch (err) {
        if (loggingEnabled['redo']) {
          console.error('⛔ Error (Redo):', err);
        }
      }
    }

    get().saveBackupToServer(canvasId, nextState);

    // Update static canvas with the new state
    get().updateStaticCanvas(canvasId, nextState);

    set({ isUndoOrRedoInProgress: false });

    return nextState;
  },

  addLayerObject: (canvasId, layer, object) => {
    set((state) => {
      const canvas = state.canvasData[canvasId];
      if (!canvas) return state;

      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...canvas,
            layersMap: {
              ...canvas.layersMap,
              [layer]: [...canvas.layersMap[layer], object],
            },
          },
        },
      };
    });
  },

  removeLayerObject: (canvasId, layer, object) => {
    set((state) => {
      const canvas = state.canvasData[canvasId];
      if (!canvas) return state;
      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...canvas,
            layersMap: {
              ...canvas.layersMap,
              [layer]: canvas.layersMap[layer].filter(
                (item) => item.object.id !== object.object.id,
              ),
            },
          },
        },
      };
    });
  },

  clearLayer: (canvasId, layer) => {
    set((state) => {
      const canvas = state.canvasData[canvasId];
      if (!canvas) return state; // Проверка существования канваса

      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...canvas,
            layersMap: {
              ...canvas.layersMap,
              [layer]: [],
            },
          },
        },
      };
    });
  },

  copyToBuffer: async (canvasId: string) => {
    const canvasState = get().canvasData[canvasId];
    if (!canvasState) {
      console.warn('⛔ Canvas not found.');
      return;
    }

    const fabricInstance = canvasState.fabricInstance;
    if (!fabricInstance) {
      console.warn('⛔ Fabric instance not found.');
      return;
    }

    console.debug('🧹 Clearing buffer before copying:', get().buffer);
    set({
      buffer: {
        objects: [],
        sourceCanvasId: canvasId,
      },
    });
    console.debug('📭 Buffer cleared:', get().buffer);

    try {
      const copiedObjects = await copyObjects(fabricInstance, canvasId);

      set({
        buffer: {
          objects: copiedObjects,
          sourceCanvasId: canvasId,
        },
      });
      console.debug('📬 Objects copied and pasted to buffer:', copiedObjects);
    } catch (error) {
      console.error('⛔ Error copying objects:', error);
    }
  },

  pasteFromBuffer: async (
    targetCanvasId: string,
    options?: {
      insertCoordinates?: { left: number; top: number };
      offsetX?: number;
      offsetY?: number;
      isNeedSetActivePastedObject?: boolean; // Activate pasted objects after pasting (true by default)
    },
  ) => {
    const { buffer, canvasData } = get();
    const canvasState = canvasData[targetCanvasId];
    if (!canvasState) {
      console.warn('⛔ Target canvas not found.');
      return;
    }

    const fabricInstance = canvasState.fabricInstance;
    if (!fabricInstance) {
      console.warn('⛔ Fabric instance not found.');
      return;
    }

    if (buffer.objects.length === 0) {
      console.warn('❓ Buffer is empty. Nothing to paste.');
      return;
    }

    try {
      await pasteObjects(fabricInstance, buffer.objects, targetCanvasId, options);
      console.debug('📥 Objects pasted from buffer to canvas:', targetCanvasId);
    } catch (error) {
      console.error('⛔ Error pasting objects:', error);
    }
  },

  closeSelectionFrame: () => {
    const { canvasData, activeCanvasId } = get();
    if (!activeCanvasId) {
      console.warn('⛔ Active canvas not found.');
      return;
    }
    const canvasState = canvasData[activeCanvasId];
    if (!canvasState) {
      console.warn('⛔ Canvas not found.');
      return;
    }
    const fabricInstance = canvasState.fabricInstance;
    if (!fabricInstance) {
      console.warn('⛔ Fabric instance not found.');
      return;
    }
    fabricInstance.discardActiveObject();
    fabricInstance.renderAll();
  },

  clearStore: () => {
    set({ ...defaultState });
  },

  updateCanvasScale: (canvasId, scale) => {
    set((state) => ({
      canvasData: {
        ...state.canvasData,
        [canvasId]: {
          ...state.canvasData[canvasId],
          canvasScale: scale,
        },
      },
    }));
  },

  registerStaticCanvas: (canvasId, instance, containerElement) => {
    set((state) => {
      const canvas = state.canvasData[canvasId];
      if (!canvas) return state;

      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...state.canvasData[canvasId],
            staticCanvas: {
              instance,
              containerElement,
            },
          },
        },
      };
    });
  },

  unregisterStaticCanvas: (canvasId) => {
    set((state) => {
      if (!(canvasId in state.canvasData)) return state;
      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...state.canvasData[canvasId],
            staticCanvas: undefined,
          },
        },
      };
    });
  },

  updateCanvasDimensions: (canvasId, dimensions) => {
    set((state) => ({
      canvasData: {
        ...state.canvasData,
        [canvasId]: { ...state.canvasData[canvasId], canvasDimensions: dimensions },
      },
    }));
  },

  updateStaticCanvas: (canvasId, canvasState) => {
    const { canvasData } = get();
    const canvas = canvasData[canvasId];
    if (!canvas || !canvas.staticCanvas) return;

    const { instance, containerElement } = canvas.staticCanvas;

    // Check if instance is valid
    if (!instance || typeof instance.loadFromJSON !== 'function') return;

    // Load state into static canvas
    instance.loadFromJSON(canvasState, () => {
      const slideMetadata = extractSlideMetadata(canvasState);
      if (!slideMetadata) return;

      // Set canvas dimensions
      const containerWidth = containerElement.clientWidth;
      const containerHeight = containerElement.clientHeight;

      // Calculate scale to fit canvas in container
      const scaleByHeight = containerHeight / slideMetadata.height;
      const scaleByWidth = containerWidth / slideMetadata.width;
      const scale = Math.min(scaleByHeight, scaleByWidth);

      // Set canvas dimensions with scale
      instance.setDimensions({
        width: slideMetadata.width * scale,
        height: slideMetadata.height * scale,
      });

      // Set scale
      instance.setZoom(scale);
      instance.setViewportTransform([scale, 0, 0, scale, 0, 0]);
      instance.requestRenderAll();
    });
  },

  // methods for object movement management
  updateMovementState: (canvasId, objects, direction) => {
    const canvas = get().canvasData[canvasId];
    if (!canvas) return false;

    const previousDirection = canvas.movementState.lastDirection;

    set((state) => {
      const canvas = state.canvasData[canvasId];
      if (!canvas) return state;

      // Store active object IDs
      const activeObjectIds = objects.map((obj) => obj.id || '').filter((id) => id);

      return {
        canvasData: {
          ...state.canvasData,
          [canvasId]: {
            ...canvas,
            movementState: {
              lastDirection: direction,
              hasPendingChanges: true,
              activeObjects: activeObjectIds,
            },
          },
        },
      };
    });

    // Check if the movement direction has changed
    return previousDirection !== null && previousDirection !== direction;
  },

  finishObjectMovement: (canvasId, forceBackup = false) => {
    const { canvasData } = get();
    const canvas = canvasData[canvasId];
    if (!canvas || !canvas.movementState.hasPendingChanges) {
      return false;
    }

    if (forceBackup) {
      // Reset state
      set((state) => {
        const canvas = state.canvasData[canvasId];
        if (!canvas) return state;

        return {
          canvasData: {
            ...state.canvasData,
            [canvasId]: {
              ...canvas,
              movementState: {
                lastDirection: null,
                hasPendingChanges: false,
                activeObjects: [],
              },
            },
          },
        };
      });

      // Create a backup
      const fabricInstance = canvas.fabricInstance;
      if (fabricInstance) {
        get().addBackup(canvasId, fabricInstance.toJSON());
      }

      return true;
    }

    return false;
  },

  checkActiveObjectsChanged: (canvasId) => {
    const { canvasData } = get();
    const canvas = canvasData[canvasId];
    if (!canvas || canvas.movementState.activeObjects.length === 0) {
      return false;
    }

    const fabricInstance = canvas.fabricInstance;
    if (!fabricInstance) {
      return false;
    }

    // Get current active objects
    const currentActiveObjects = fabricInstance
      .getActiveObjects()
      .map((obj) => obj.id || '')
      .filter((id) => id);

    // Check if there's a difference in the set of active objects
    if (currentActiveObjects.length !== canvas.movementState.activeObjects.length) {
      return true;
    }

    // Check if all previously active objects are still active
    return !canvas.movementState.activeObjects.every((id) => currentActiveObjects.includes(id));
  },
}));
