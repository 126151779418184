/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Canvas,
  Object as FabricObject,
  util as fabricUtil,
  Group,
  Image,
  FabricImage,
  StaticCanvas,
  Path,
} from 'fabric';

import { deleteMaskImage } from '@app/widgets/canvas/utils/crop';

import { useCanvasStore } from '../store';

import { MenuItem, Layer } from '../types/types';

import { scaleImage } from './imageUtils';
import { performActionOnObjectInGroup } from './groupUtils';
import { applyLockToObject } from './lockObjects';
import { extractSlideMetadata } from './slideMetadata';
import { setCanvasBackgroundImage } from './backgroundImage';

type MenuItemWithBackup = MenuItem & {
  addBackup: (canvasId: string, data: any) => void;
};

const layerPriority = ['background', 'middle', 'foreground', 'overlay'];

export const addObjectToLayer = (
  canvasId: string,
  layer: Layer,
  object: FabricObject,
  zIndex: number,
) => {
  // Get state from store
  const { getCanvasState } = useCanvasStore.getState();

  // Get canvas data
  const canvasData = getCanvasState(canvasId);
  if (!canvasData) {
    console.warn(`Canvas with ID ${canvasId} not found.`);
    return;
  }
  // Set zIndex
  changeZIndex(canvasId, layer, object, zIndex);
};

export const setLayerVisibility = (
  canvasId: string, // Now passing canvasId
  layerVisibility: { [key in Layer]: boolean },
) => {
  const { getCanvasState } = useCanvasStore.getState();

  // Get canvas data
  const canvasData = getCanvasState(canvasId);
  if (!canvasData) {
    console.warn(`Canvas with ID ${canvasId} not found.`);
    return;
  }

  const { fabricInstance, layersMap } = canvasData;

  if (!fabricInstance || !layersMap) {
    console.warn(`Canvas with ID ${canvasId} not found.`);
    return;
  }
  // Iterate through all layers
  Object.keys(layersMap).forEach((layer) => {
    const isVisible = layerVisibility[layer as Layer] ?? true; // Show layer by default

    layersMap[layer as Layer].forEach((layerObject) => {
      layerObject.object.set('visible', isVisible);
    });
  });

  // Update display
  fabricInstance.renderAll();
  fabricInstance.requestRenderAll();
};

export const changeZIndex = (
  canvasId: string, // Passing canvasId
  layer: Layer,
  object: FabricObject,
  newZIndex: number,
) => {
  const { getCanvasState, addLayerObject, removeLayerObject } = useCanvasStore.getState();

  // Get canvas data
  const canvasData = getCanvasState(canvasId);
  if (!canvasData) {
    console.warn(`Canvas with ID ${canvasId} not found.`);
    return;
  }

  const { layersMap, fabricInstance } = canvasData;

  // Find object in layer
  const layerObject = layersMap[layer].find((lo) => lo.object === object);

  if (layerObject) {
    // Remove object from current layer
    removeLayerObject(canvasId, layer, layerObject);

    // Update zIndex
    layerObject.zIndex = newZIndex;

    // Add object back to layer with updated zIndex
    addLayerObject(canvasId, layer, layerObject);

    // Get all objects from all layers
    const allLayerObjects = Object.values(layersMap).flat();

    // Sort all objects by layer priority and zIndex
    allLayerObjects.sort((a, b) => {
      const layerIndexA = layerPriority.indexOf(a.layer);
      const layerIndexB = layerPriority.indexOf(b.layer);

      // Compare layer order
      if (layerIndexA !== layerIndexB) {
        return layerIndexA - layerIndexB;
      }

      // If layers are the same, sort by zIndex
      return a.zIndex - b.zIndex;
    });

    // Clear canvas and add objects in correct order
    fabricInstance.clear();
    allLayerObjects.forEach((layerObject) => {
      fabricInstance.add(layerObject.object);
    });
  }
};

export const makeColorizedUrlForObject = async (object: any, color: string) => {
  if (Object.hasOwn(object, 'src')) {
    if (object.src.includes('{color}')) {
      object.src = object.src.replace('{color}', color);
    }
  }
};

export const loadObjectsWithLayers = async (
  canvas: Canvas | StaticCanvas,
  canvasId: string,
  {
    objects,
    background: backgroundColor,
  }: { version: string; objects: any[]; background?: string },
) => {
  const { addLayerObject, updateMetaData } = useCanvasStore.getState();
  const { width: slideWidth, height: slideHeight } = extractSlideMetadata({ objects }) || {
    width: 0,
    height: 0,
  };

  canvas.clear();

  // canvasData.layersMap = {
  //   background: [],
  //   middle: [],
  //   foreground: [],
  //   overlay: [],
  // };

  // Create map for grouping objects by layers
  const newLayersMap: { [key in Layer]: FabricObject[] } = {
    background: [],
    middle: [],
    foreground: [],
    overlay: [],
  };

  let isBackgroundSetted = false;

  // Iterate through each object in the data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  for (const objData of objects) {
    const layerName = (objData.layer as Layer) || 'background';

    if (objData.type.toLowerCase() === 'group') {
      // Process group (bullets)
      try {
        // Iterate through bullet group objects and if the image has a color field - we need to create a colorized url for this image
        for (let index = 0; index < objData.objects.length; index++) {
          if (objData.objects[index].type.toLowerCase() === 'image') {
            const imageObject = objData.objects[index] as Image;
            // If the image has a color field - store it in the object
            if (imageObject.imageColor) {
              imageObject.imageColor = objData.objects[index].imageColor;
              // Create colorized url for the image
              makeColorizedUrlForObject(imageObject, objData.objects[index].imageColor);
            }
          }
        }
        const groupObjects = await fabricUtil.enlivenObjects(objData.objects);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const group = new Group(groupObjects as unknown as FabricObject[], {
          left: objData.left,
          top: objData.top,
          width: objData.width,
          height: objData.height,
          layer: objData.layer,
          zIndex: objData.zIndex,
          id: objData.id,
          name: objData.name,
          category: objData.category,
          lockScalingX: objData.lockScalingX,
          lockScalingY: objData.lockScalingY,
          lockRotation: objData.lockRotation,
          hasControls: objData.hasControls,
          originX: objData.originX,
          originY: objData.originY,
        });
        // If the group has the isLocked property, apply the lock to the group
        if (objData.isLocked) {
          applyLockToObject(group, true);
        }
        group.subTargetCheck = true; // Enable sub-object checking in the group (clicks inside the group)
        // Assign parameters to each object within the group
        for (let index = 0; index < groupObjects.length; index++) {
          const obj = group.getObjects()[index];
          obj.id = objData.objects[index].id;
          obj.category = objData.objects[index].category;
          obj.idGroup = objData.objects[index].idGroup;
          obj.noCopy = objData.objects[index].noCopy;
          obj.originX = objData.objects[index].originX;
          obj.originY = objData.objects[index].originY;
          obj.shape_type = objData.objects[index].shape_type;
          obj.clip_path = objData.objects[index].clip_path;
          obj.inner_id = objData.objects[index].inner_id;

          // Apply lock state to objects inside the group
          if (objData.objects[index].isLocked === true) {
            applyLockToObject(obj, true);
          }
          if (obj?.type?.toLowerCase() === 'image') {
            const imageObject = obj as Image;
            //It is need for backend. type image.
            imageObject.media_type = objData.objects[index].media_type;
            imageObject.source_ext = objData.objects[index].source_ext;
            imageObject.source_width = objData.objects[index].source_width; //width of the image
            imageObject.source_height = objData.objects[index].source_height; //height of the image
            imageObject.crop = objData.objects[index].crop; //crop of the image

            //If the flag exists and is true, scale the image
            if (!('isNeedScaleImgForFirstRender' in obj) || obj.isNeedScaleImgForFirstRender) {
              scaleImage(imageObject, imageObject.width, imageObject.height);
            }
          }
          if (obj?.type?.toLocaleLowerCase() === 'path') {
            obj.set('shape_type', objData.objects[index].shape_type);
          }
          if (obj?.type?.toLocaleLowerCase() === 'textbox') {
            obj.set('margin', objData.objects[index].margin);
            obj.set('space', objData.objects[index].space);
          }
        }
        // Add group to the appropriate layer
        newLayersMap[layerName].push(group);
      } catch (e) {
        console.error('Error loading bullet', e);
      }
    } else {
      // Process regular object
      try {
        const objects = await fabricUtil.enlivenObjects([objData]);
        // Process metadata from backend configuration
        if (objData.category) {
          if (objData.category.toLowerCase() === 'metadata') {
            updateMetaData(canvasId, objData);
          }
          // If background is set at the same level as objects
          if (backgroundColor) {
            canvas.backgroundColor = backgroundColor;
            isBackgroundSetted = true;
          } else if (objData.category.toLowerCase() === 'background') {
            // If not - look in meta-objects
            if (objData.backgroundColor) {
              canvas.backgroundColor = objData.backgroundColor;
              isBackgroundSetted = true;
            }
            if (objData.backgroundImage) {
              try {
                // const imgData = await Image.fromURL(objData.backgroundImage);
                // canvas.backgroundImage = imgData;

                await setCanvasBackgroundImage(
                  canvas,
                  objData.backgroundImage,
                  slideWidth,
                  slideHeight,
                );
                isBackgroundSetted = true;
              } catch (error) {
                console.error('Error loading background image:', error);
              }
            }
          }
        }

        objects.forEach(async (obj) => {
          // If the object has the isLocked property, apply the lock to the object
          if ('isLocked' in obj && obj.isLocked) {
            applyLockToObject(obj, true);
          }
          if ('type' in obj && obj.type.toLowerCase() === 'image' && obj instanceof Image) {
            const imageObject = obj; // Convert object to Fabric.js image
            // If isNeedScaleImgForFirstRender flag exists and is true, scale the image
            if ('isNeedScaleImgForFirstRender' in obj && obj.isNeedScaleImgForFirstRender) {
              scaleImage(imageObject, imageObject.width, imageObject.height); // Scale the image
              newLayersMap[layerName].push(imageObject);
            }
            // If the flag exists but is false, just add the object without scaling
            else if ('isNeedScaleImgForFirstRender' in obj && !obj.isNeedScaleImgForFirstRender) {
              newLayersMap[layerName].push(imageObject);
            }
            // If the flag is missing, scale and set the flag to false
            else {
              scaleImage(imageObject, imageObject.width, imageObject.height); // Scale the image
              newLayersMap[layerName].push(imageObject);
            }
          } else {
            // If it's not an image, add the object as is
            newLayersMap[layerName].push(obj as FabricObject);
          }
        });
      } catch (e) {
        console.error('Error loading single object', e);
      }
    }
  }

  // Save new objects to Zustand store
  for (const layer in newLayersMap) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const objectsInLayer = newLayersMap[layer];

    // Sort objects in layer by zIndex
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const sortedObjects = objectsInLayer.sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0));

    // Add sorted objects to canvas
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    sortedObjects.forEach((obj) => {
      canvas.add(obj);
      // Update layersMap in state
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      addLayerObject(canvasId, layer, { object: obj, zIndex: obj.zIndex || 0, layer });
    });
  }

  // If background color is not set, set it to white
  if (!isBackgroundSetted) {
    canvas.backgroundColor = 'white';
  }

  canvas.renderAll();
  return newLayersMap;
};

// TODO: Move to context menu slice
export const deleteItems = ({
  targetIds,
  canvas,
  canvasId,
  addBackup,
}: Omit<MenuItemWithBackup, 'item'>) => {
  const idsToDelete = Array.isArray(targetIds) ? targetIds : [targetIds];
  const activeObjects = canvas.getActiveObjects();
  idsToDelete.forEach((currentId) => {
    const targetObject = canvas.getObjects().find((object) => {
      if (object.id === currentId) {
        return true;
      } else if (object.category) {
        // If the object to delete is in a group (bullets)
        if (object.category.toLowerCase() === 'bullet' && object instanceof Group) {
          performActionOnObjectInGroup(object, currentId, (obj) => {
            object.remove(obj); // Remove object from group
          });
          return; // Object found and processed
        }
      }
    });
    if (
      targetObject !== undefined &&
      targetObject.type.toLowerCase() === 'image' &&
      targetObject.category === 'crop'
    ) {
      const imageObject = targetObject as FabricImage;
      deleteMaskImage(canvas, imageObject);
    }

    if (targetObject) {
      const activeCanvasId = useCanvasStore.getState().activeCanvasId;
      if (activeCanvasId) {
        useCanvasStore.getState().removeLayerObject(activeCanvasId, targetObject.layer, {
          object: targetObject,
          zIndex: targetObject.zIndex,
          layer: targetObject.layer,
        });
      }
      canvas.remove(targetObject);
    }
  });
  if (activeObjects.length > 0) {
    canvas.discardActiveObject();
  }
  const cfg = canvas.toJSON();
  addBackup(canvasId, cfg);
  canvas.requestRenderAll();
  // loadObjectsWithLayers(canvasId, cfg);
};

// TODO: Move to context menu slice
export const colorizeObjects = ({
  color,
  targetIds,
  canvas,
  canvasId,
  addBackup,
}: MenuItemWithBackup & { color: string }) => {
  targetIds.forEach((id) => {
    const obj = canvas.getObjects().find((o: FabricObject) => o.id === id);
    if (obj) {
      obj.set('fill', color);
    }
  });
  addBackup(canvasId, canvas.toJSON());
  canvas.renderAll();
};

export const setRotationControlOffset = (obj: FabricObject, offsetY: number = -40) => {
  if (!obj) return;

  // check if object has controls
  if (!obj.controls) {
    obj.setControlsVisibility({
      mtr: true,
    });
  }

  if (obj.controls && obj.controls.mtr) {
    // Учитываем масштаб канваса при расчете отступа
    const canvasScale = obj.canvas ? obj.canvas.getZoom() : 1;

    // Ограничиваем масштаб для точки вращения, чтобы она не уезжала слишком далеко
    // Минимальное значение масштаба для расчета
    const minScale = 0.9;
    // Максимальное значение масштаба для расчета
    const maxScale = 1.5;

    // Эффективный масштаб для расчета отступа
    const effectiveScale = Math.min(Math.max(canvasScale, minScale), maxScale);

    // Расчет масштабированного отступа
    const scaledOffsetY = offsetY / effectiveScale;

    // Применяем масштабированный отступ
    obj.controls.mtr.offsetY = scaledOffsetY;

    // Для activeSelection дополнительно проверим и установим offsetX
    if (obj.type && obj.type.toLowerCase() === 'activeselection') {
      // Убеждаемся, что контрол поворота не смещен по горизонтали
      obj.controls.mtr.offsetX = 0;
    }

    // Отметим, что контролы должны быть перерисованы
    obj.setCoords();

    // Если объект находится на холсте, запросим перерисовку
    if (obj.canvas) {
      obj.canvas.requestRenderAll();
    }
  }
};

export const toggleGroupBackground = (canvas: Canvas, groupId: string, color = '') => {
  canvas.getObjects().forEach((obj) => {
    if (obj.id === groupId) {
      obj.set('backgroundColor', color);
    }
  });
};

//TODO: types for pathConfig
export const createImageFromPath = (
  pathConfig: any,
  canvasWidth: number,
  canvasHeight: number,
  backgroundColor = '#292929',
) => {
  return new Promise((resolve, reject) => {
    try {
      const pathObject = new Path(pathConfig.path, {
        left: 0,
        top: 0,
        fill: pathConfig.fill,
        stroke: pathConfig.stroke,
        strokeWidth: pathConfig.strokeWidth,
      });

      const tempCanvas = new StaticCanvas(document.createElement('canvas'), {
        backgroundColor,
      });

      tempCanvas.setWidth(canvasWidth);
      tempCanvas.setHeight(canvasHeight);

      tempCanvas.add(pathObject);
      const imageURL = tempCanvas.toDataURL({
        format: 'png',
        multiplier: 2,
      });

      resolve(imageURL);
    } catch (error) {
      reject(error);
    }
  });
};

//TODO: types for pathConfig
export const convertPathToSVG = (pathConfig: any, width = 15, height = 15) => {
  const svgPath = `<path d="${pathConfig.path}" 
                        fill="${pathConfig.fill}" 
                        stroke="${pathConfig.stroke}" 
                        stroke-width="${pathConfig.strokeWidth}" 
                        />`;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
                ${svgPath}
              </svg>`;

  return svg;
};
