/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Canvas, Object as FabricObject, Group, Textbox } from 'fabric';

import { bubble } from '@app/app/lib';

import { useCanvasStore } from '../store';

import { MenuItem, type AlignmentText } from '../types/types';

import { CANVAS_EVENTS, SELECTORS } from '../config/constants';

import { performActionOnObjectInGroup } from './groupUtils';

type MenuItemWithBackup = MenuItem & {
  addBackup: (canvasId: string, data: any) => void;
};

//if there is a text object in the group, disable scaling and rotation (since text cannot be stretched)
export const disableScalingForTextboxInGroup = (canvas: Canvas | null) => {
  if (!canvas) return;
  const activeObjects = canvas.getActiveObjects();
  const activeGroupSelection = canvas.getActiveObject();

  let hasTextObject = false;
  if (activeObjects)
    hasTextObject = activeObjects.some((obj: any) => {
      if (obj.type.toLowerCase() === 'textbox') {
        return true;
      } else if (obj.type.toLowerCase() === 'group' && obj instanceof Group) {
        return obj.getObjects().some((o) => o.type.toLowerCase() === 'textbox');
      }
      return false;
    });
  if (activeObjects.length > 1) {
    if (hasTextObject) {
      activeGroupSelection?.set({
        lockScalingX: true,
        lockScalingY: true,
      });
      activeGroupSelection?.setControlsVisibility({
        tl: false, // Top left corner
        tr: false, // Top right corner
        bl: false, // Bottom left corner
        br: false, // Bottom right corner
        ml: false, // Middle left
        mt: false, // Middle top
        mr: false, // Middle right
        mb: false, // Middle bottom
        mtr: false, // Rotation control
      });
    }
  } else if (activeObjects.length === 1 && hasTextObject) {
    activeGroupSelection?.setControlsVisibility({
      tl: false, // Top left corner
      tr: false, // Top right corner
      bl: false, // Bottom left corner
      br: false, // Bottom right corner
      ml: true, // Middle left
      mt: false, // Middle top
      mr: true, // Middle right
      mb: false, // Middle bottom
      mtr: false, // Rotation control
    });
  }
};

export const colorizeText = ({
  color,
  canvas,
  targetIds,
  canvasId,
  addBackup,
}: MenuItemWithBackup & { color: string }) => {
  const canvasObjects = canvas.getObjects();
  targetIds.forEach((id) => {
    const obj = canvasObjects.find((o: FabricObject) => {
      if (o.id === id) {
        return true;
      } else if (o.category) {
        //if the object to be deleted is in a group (bullets)
        if (o.category.toLowerCase() === 'bullet' && o instanceof Group) {
          performActionOnObjectInGroup(o, id, (obj) => {
            if (obj.type.toLowerCase() === 'textbox' && obj instanceof Textbox) {
              // When user modifies text color, we assume they want to keep this version of text
              // and don't need to restore the original text anymore
              obj.set({
                fill: color,
                is_truncated: false,
              });
              //iterate through all styles and change only the necessary ones
              Object.keys(obj.styles).forEach((key) => {
                const s = obj.styles[key];
                Object.values(s).forEach((style: any) => {
                  if (style.fill) {
                    delete style.fill; //remove fill
                  }
                });
              });
              obj.dirty = true;
            }
          });
          return; //object found and processed
        }
      }
    });
    if (obj instanceof Textbox) {
      if (obj && obj.type.toLowerCase() === 'textbox') {
        try {
          // When user modifies text color, we assume they want to keep this version of text
          // and don't need to restore the original text anymore
          obj.set('is_truncated', false);

          if (obj.selectionStart === obj.selectionEnd) {
            // No selection -> colorize the whole text
            obj.set('fill', color);
            //iterate through all styles and change only the necessary ones
            Object.keys(obj.styles).forEach((key) => {
              const s = obj.styles[key];
              Object.values(s).forEach((style: any) => {
                if (style.fill) {
                  delete style.fill; //remove fill
                }
              });
            });
            obj.dirty = true;
          } else {
            // There is selection -> colorize only the selected part
            obj.setSelectionStyles({ fill: color }, obj.selectionStart, obj.selectionEnd);
            obj.dirty = true;
          }
        } catch (e) {
          console.error('⛔ Error change color', e);
        }
      }
    }
    const cfg = canvas.toJSON();
    addBackup(canvasId, cfg);
    canvas.requestRenderAll();
  });
};

export const alignText = ({
  targetIds,
  canvas,
  canvasId,
  pos,
}: MenuItem & { pos: AlignmentText }) => {
  const { addBackup } = useCanvasStore.getState();
  const idsToAction = Array.isArray(targetIds) ? targetIds : [targetIds];
  idsToAction.forEach((currentId) => {
    const obj = canvas.getObjects().find((o) => {
      if (o.id === currentId) {
        return true;
      } else if (o.category) {
        //if the object to be deleted is in a group (bullets)
        if (o.category.toLowerCase() === 'bullet' && o instanceof Group) {
          performActionOnObjectInGroup(o, currentId, (obj) => {
            // When user modifies text alignment, we assume they want to keep this version of text
            // and don't need to restore the original text anymore
            obj.set({
              textAlign: pos,
              is_truncated: false,
            });
          });
          return; //object found and processed
        }
      }
    });
    if (obj) {
      // When user modifies text alignment, we assume they want to keep this version of text
      // and don't need to restore the original text anymore
      obj.set({
        textAlign: pos,
        is_truncated: false,
      });
    }
  });
  addBackup(canvasId, canvas.toJSON());
  canvas.renderAll(); //TODO: more likely will be loadObjectsWithLayers
};

export const adjustFontSize = (
  { targetIds, canvas, canvasId, addBackup }: MenuItemWithBackup,
  adjustment: number,
  isNeedUpdateContextMenu = false,
) => {
  try {
    const objects = canvas.getObjects();
    targetIds.forEach((id) => {
      const targetObject = objects.find((obj) => {
        if (obj.id === id) {
          return true;
        } else if (obj.category) {
          //if the object to be deleted is in a group (bullets)
          if (obj.category.toLowerCase() === 'bullet' && obj instanceof Group) {
            performActionOnObjectInGroup(obj, id, (innerObj) => {
              if (innerObj.type.toLowerCase() === 'textbox') {
                const textObject = innerObj as Textbox;
                const currentFontSize = textObject.fontSize || 16;
                const newFontSize = Math.max(1, currentFontSize + adjustment);

                // When user modifies font size, we assume they want to keep this version of text
                // and don't need to restore the original text anymore
                textObject.set({
                  fontSize: newFontSize,
                  is_truncated: false,
                });
              }
            });
            return; //object found and processed
          }
        }
      });

      if (targetObject && targetObject.type.toLowerCase() === 'textbox') {
        const textObject = targetObject as Textbox;
        const currentFontSize = textObject.fontSize || 16;
        const newFontSize = Math.max(1, currentFontSize + adjustment);

        // When user modifies font size, we assume they want to keep this version of text
        // and don't need to restore the original text anymore
        textObject.set({
          fontSize: newFontSize,
          is_truncated: false,
        });
      }
    });
    const cfg = canvas.toJSON();
    addBackup(canvasId, cfg);
    canvas.requestRenderAll();
    if (isNeedUpdateContextMenu) {
      //is need because of the context menu is not updated automatically (not working event canvas.on('object:modified'))
      const canvasEl = document.querySelector(SELECTORS.canvas(canvasId));
      if (canvasEl) {
        bubble(canvasEl as HTMLElement, CANVAS_EVENTS.UPDATE_CONTEXT_MENU);
      }
    }
  } catch (error) {
    console.error('Error adjusting font size:', error);
  }
};

export const applyFontStyle = ({
  style,
  canvas,
  targetIds,
  canvasId,
  addBackup,
}: MenuItemWithBackup & { style: 'bold' | 'regular' | 'underline' }) => {
  const canvasObjects = canvas.getObjects();
  targetIds.forEach((id) => {
    const obj = canvasObjects.find((o: FabricObject) => {
      if (o.id === id) {
        return true;
      } else if (o.category) {
        //if the object to be deleted is in a group (bullets)
        if (o.category.toLowerCase() === 'bullet' && o instanceof Group) {
          performActionOnObjectInGroup(o, id, (obj) => {
            if (obj.type.toLowerCase() === 'textbox' && obj instanceof Textbox) {
              // Save current width before changing style
              const currentWidth = obj.width;

              // When user modifies font style, we assume they want to keep this version of text
              // and don't need to restore the original text anymore
              obj.set('is_truncated', false);

              switch (style) {
                case 'regular':
                  obj.set({
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    underline: false,
                  });
                  //processing obj.styles (standard Fabric.js format)
                  Object.keys(obj.styles).forEach((key) => {
                    const s = obj.styles[key];
                    Object.values(s).forEach((style: any) => {
                      if (style.fontWeight) delete style.fontWeight;
                      if (style.underline) delete style.underline;
                    });
                  });

                  //processing array styles with fields start, end, style
                  if (Array.isArray(obj.styles)) {
                    obj.styles.forEach((styleItem: any) => {
                      if (styleItem.style) {
                        if (styleItem.style.fontWeight) delete styleItem.style.fontWeight;
                        if (styleItem.style.underline) delete styleItem.style.underline;
                      }
                    });
                  }
                  obj.dirty = true;
                  break;
                case 'bold':
                  obj.set({
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    underline: false,
                  });
                  //processing obj.styles (standard Fabric.js format)
                  Object.keys(obj.styles).forEach((key) => {
                    const s = obj.styles[key];
                    Object.values(s).forEach((style: any) => {
                      style.fontWeight = 'bold';
                      //save other styles if they exist
                    });
                  });

                  //processing array styles with fields start, end, style
                  if (Array.isArray(obj.styles)) {
                    obj.styles.forEach((styleItem: any) => {
                      if (styleItem.style) {
                        styleItem.style.fontWeight = 'bold';
                      }
                    });
                  }
                  obj.dirty = true;
                  break;
                case 'underline':
                  obj.set({
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    underline: true,
                  });
                  //processing obj.styles (standard Fabric.js format)
                  Object.keys(obj.styles).forEach((key) => {
                    const s = obj.styles[key];
                    Object.values(s).forEach((style: any) => {
                      style.underline = true;
                      //save other styles if they exist
                    });
                  });

                  //processing array styles with fields start, end, style
                  if (Array.isArray(obj.styles)) {
                    obj.styles.forEach((styleItem: any) => {
                      if (styleItem.style) {
                        styleItem.style.underline = true;
                      }
                    });
                  }
                  obj.dirty = true;
                  break;
              }

              // Restore original width after changing style
              obj.set('width', currentWidth);
            }
          });
          return; //object found and processed
        }
      }
    });
    if (obj && obj.type.toLowerCase() === 'textbox') {
      try {
        if (obj instanceof Textbox) {
          // Save current width before changing style
          const currentWidth = obj.width;

          // When user modifies font style, we assume they want to keep this version of text
          // and don't need to restore the original text anymore
          obj.set('is_truncated', false);

          if (obj.selectionStart === obj.selectionEnd) {
            // No selection -> apply style to the whole text
            switch (style) {
              case 'regular':
                obj.set({
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  underline: false,
                });
                //processing obj.styles (standard Fabric.js format)
                Object.keys(obj.styles).forEach((key) => {
                  const s = obj.styles[key];
                  Object.values(s).forEach((style: any) => {
                    if (style.fontWeight) delete style.fontWeight;
                    if (style.underline) delete style.underline;
                  });
                });

                //processing array styles with fields start, end, style
                if (Array.isArray(obj.styles)) {
                  obj.styles.forEach((styleItem: any) => {
                    if (styleItem.style) {
                      if (styleItem.style.fontWeight) delete styleItem.style.fontWeight;
                      if (styleItem.style.underline) delete styleItem.style.underline;
                    }
                  });
                }
                obj.dirty = true;
                break;
              case 'bold':
                obj.set({
                  fontWeight: 'bold',
                  fontStyle: 'normal',
                  underline: false,
                });
                //processing obj.styles (standard Fabric.js format)
                Object.keys(obj.styles).forEach((key) => {
                  const s = obj.styles[key];
                  Object.values(s).forEach((style: any) => {
                    style.fontWeight = 'bold';
                    //save other styles if they exist
                  });
                });

                //processing array styles with fields start, end, style
                if (Array.isArray(obj.styles)) {
                  obj.styles.forEach((styleItem: any) => {
                    if (styleItem.style) {
                      styleItem.style.fontWeight = 'bold';
                    }
                  });
                }
                obj.dirty = true;
                break;
              case 'underline':
                obj.set({
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  underline: true,
                });
                //processing obj.styles (standard Fabric.js format)
                Object.keys(obj.styles).forEach((key) => {
                  const s = obj.styles[key];
                  Object.values(s).forEach((style: any) => {
                    style.underline = true;
                    //save other styles if they exist
                  });
                });

                //processing array styles with fields start, end, style
                if (Array.isArray(obj.styles)) {
                  obj.styles.forEach((styleItem: any) => {
                    if (styleItem.style) {
                      styleItem.style.underline = true;
                    }
                  });
                }
                obj.dirty = true;
                break;
            }
          } else {
            // There is selection -> apply style only to the selected part
            const selectionStyles: any = {};
            switch (style) {
              case 'regular':
                selectionStyles.fontWeight = 'normal';
                selectionStyles.fontStyle = 'normal';
                selectionStyles.underline = false;
                break;
              case 'bold':
                selectionStyles.fontWeight = 'bold';
                break;
              case 'underline':
                selectionStyles.underline = true;
                break;
            }
            obj.setSelectionStyles(selectionStyles, obj.selectionStart, obj.selectionEnd);

            //processing array styles with fields start, end, style for selected text
            if (Array.isArray(obj.styles)) {
              const start = obj.selectionStart;
              const end = obj.selectionEnd;

              //check if there is already a style for this range
              const existingStyleIndex = obj.styles.findIndex(
                (styleItem: any) => styleItem.start === start && styleItem.end === end,
              );

              if (existingStyleIndex !== -1) {
                //update existing style
                switch (style) {
                  case 'regular':
                    if (obj.styles[existingStyleIndex].style.fontWeight)
                      delete obj.styles[existingStyleIndex].style.fontWeight;
                    if (obj.styles[existingStyleIndex].style.underline)
                      delete obj.styles[existingStyleIndex].style.underline;
                    break;
                  case 'bold':
                    obj.styles[existingStyleIndex].style.fontWeight = 'bold';
                    break;
                  case 'underline':
                    obj.styles[existingStyleIndex].style.underline = true;
                    break;
                }
              } else {
                //create new style for selected range
                const newStyle: any = { start, end, style: {} };
                switch (style) {
                  case 'regular':
                    newStyle.style.fontWeight = 'normal';
                    newStyle.style.underline = false;
                    break;
                  case 'bold':
                    newStyle.style.fontWeight = 'bold';
                    break;
                  case 'underline':
                    newStyle.style.underline = true;
                    break;
                }
                obj.styles.push(newStyle);
              }
            }

            obj.dirty = true;
          }

          // Restore original width after changing style
          obj.set('width', currentWidth);
        }
      } catch (e) {
        console.error('⛔ Error', e);
      }
    }
  });
  addBackup(canvasId, canvas.toJSON());
  canvas.renderAll();
};

export const getActiveTextColor = (textObject: Textbox): string | string[] => {
  // Если объект не является текстом, возвращаем пустое значение
  if (!textObject || textObject.type.toLowerCase() !== 'textbox') {
    return '';
  }

  // Проверяем, есть ли выделение
  const hasSelection = textObject.selectionStart !== textObject.selectionEnd;

  // Если есть стили в формате массива объектов со свойствами start, end, style
  if (Array.isArray(textObject.styles)) {
    // Если есть выделение, ищем стили, которые пересекаются с выделением
    if (hasSelection) {
      const selectionStart = textObject.selectionStart;
      const selectionEnd = textObject.selectionEnd;

      const colorsInSelection = new Set<string>();

      textObject.styles.forEach((styleItem: any) => {
        // Проверяем, пересекается ли стиль с выделением
        if (
          styleItem.style &&
          styleItem.style.fill &&
          ((styleItem.start <= selectionStart && styleItem.end > selectionStart) ||
            (styleItem.start >= selectionStart && styleItem.start < selectionEnd))
        ) {
          colorsInSelection.add(styleItem.style.fill.toString());
        }
      });

      // Если в выделении нет цветов из стилей, используем общий цвет
      if (colorsInSelection.size === 0) {
        const fill = textObject.get('fill');
        return fill ? fill.toString() : '';
      }

      // Если найден только один цвет, возвращаем его
      if (colorsInSelection.size === 1) {
        return Array.from(colorsInSelection)[0];
      }

      // Иначе возвращаем массив цветов
      return Array.from(colorsInSelection);
    }

    // Если нет выделения, проверяем, сколько разных цветов есть в тексте
    const uniqueColors = new Set<string>();

    textObject.styles.forEach((styleItem: any) => {
      if (styleItem.style && styleItem.style.fill) {
        uniqueColors.add(styleItem.style.fill.toString());
      }
    });

    // Если нет цветов в стилях или только один цвет, возвращаем общий цвет
    if (uniqueColors.size <= 1) {
      // Если единственный цвет в стилях совпадает с общим цветом или стилей нет,
      // возвращаем общий цвет
      const fill = textObject.get('fill');
      const fillColor = fill ? fill.toString() : '';
      return uniqueColors.size === 0 ? fillColor : Array.from(uniqueColors)[0];
    }

    // Если разные части текста имеют разные цвета, возвращаем массив
    return Array.from(uniqueColors);
  }

  // Проверяем стили в формате Fabric.js (obj.styles[line][char])
  if (textObject.styles && typeof textObject.styles === 'object') {
    const uniqueColors = new Set<string>();
    let hasStyles = false;

    // Если есть выделение, проверяем только стили в выделении
    if (hasSelection) {
      const selectionStart = textObject.selectionStart;
      const selectionEnd = textObject.selectionEnd;

      let lineIndex = 0;
      let charIndex = 0;
      let currentPos = 0;

      // Проходим по всем строкам и символам, чтобы найти стили в выделении
      for (const lineNum in textObject.styles) {
        lineIndex = parseInt(lineNum, 10);
        const line = textObject.styles[lineNum];

        for (const charNum in line) {
          charIndex = parseInt(charNum, 10);
          currentPos = lineIndex * textObject._textLines[lineIndex].length + charIndex;

          // Если символ в пределах выделения
          if (currentPos >= selectionStart && currentPos < selectionEnd) {
            const style = line[charNum];
            if (style && style.fill) {
              uniqueColors.add(style.fill.toString());
              hasStyles = true;
            }
          }
        }
      }

      // Если в выделении нет цветов из стилей, используем общий цвет
      if (!hasStyles) {
        const fill = textObject.get('fill');
        return fill ? fill.toString() : '';
      }

      // Если найден только один цвет, возвращаем его
      if (uniqueColors.size === 1) {
        return Array.from(uniqueColors)[0];
      }

      // Иначе возвращаем массив цветов
      return Array.from(uniqueColors);
    }

    // Если нет выделения, проверяем все стили
    for (const lineNum in textObject.styles) {
      const line = textObject.styles[lineNum];
      for (const charNum in line) {
        const style = line[charNum];
        if (style && style.fill) {
          uniqueColors.add(style.fill.toString());
          hasStyles = true;
        }
      }
    }

    // Если нет стилей или только один цвет, возвращаем общий цвет
    if (!hasStyles || uniqueColors.size <= 1) {
      const fill = textObject.get('fill');
      const fillColor = fill ? fill.toString() : '';
      return uniqueColors.size === 0 ? fillColor : Array.from(uniqueColors)[0];
    }

    // Если разные части текста имеют разные цвета, возвращаем массив
    return Array.from(uniqueColors);
  }

  // Если нет стилей, возвращаем общий цвет
  const fill = textObject.get('fill');
  return fill ? fill.toString() : '';
};

/**
 * Проверяет, является ли текст или выделенная часть текста подчеркнутой
 * @param textObject Текстовый объект Fabric.js
 * @returns true, если текст подчеркнут, false - если не подчеркнут, undefined - если текст содержит части с разными стилями
 */
export const isTextUnderlined = (textObject: Textbox): boolean | undefined => {
  // Если объект не является текстом, возвращаем false
  if (!textObject || textObject.type.toLowerCase() !== 'textbox') {
    return false;
  }

  // Проверяем, есть ли выделение
  const hasSelection = textObject.selectionStart !== textObject.selectionEnd;

  // Если есть стили в формате массива объектов со свойствами start, end, style
  if (Array.isArray(textObject.styles)) {
    // Если есть выделение, проверяем только стили в выделении
    if (hasSelection) {
      const selectionStart = textObject.selectionStart;
      const selectionEnd = textObject.selectionEnd;

      const underlineStates = new Set<boolean>();
      let hasStylesInSelection = false;

      textObject.styles.forEach((styleItem: any) => {
        // Проверяем, пересекается ли стиль с выделением
        if (
          styleItem.style &&
          ((styleItem.start <= selectionStart && styleItem.end > selectionStart) ||
            (styleItem.start >= selectionStart && styleItem.start < selectionEnd))
        ) {
          if (styleItem.style.underline !== undefined) {
            underlineStates.add(!!styleItem.style.underline);
            hasStylesInSelection = true;
          }
        }
      });

      // Если нет стилей в выделении, используем общий стиль
      if (!hasStylesInSelection) {
        return !!textObject.underline;
      }

      // Если все части выделения имеют одинаковый стиль подчеркивания
      if (underlineStates.size === 1) {
        return Array.from(underlineStates)[0];
      }

      // Если разные части выделения имеют разные стили подчеркивания
      return undefined;
    }

    // Если нет выделения, проверяем все стили
    const underlineStates = new Set<boolean>();
    let hasStyles = false;

    textObject.styles.forEach((styleItem: any) => {
      if (styleItem.style && styleItem.style.underline !== undefined) {
        underlineStates.add(!!styleItem.style.underline);
        hasStyles = true;
      }
    });

    // Если нет специфических стилей, используем общий стиль
    if (!hasStyles) {
      return !!textObject.underline;
    }

    // Если все части текста имеют одинаковый стиль подчеркивания
    if (underlineStates.size === 1) {
      return Array.from(underlineStates)[0];
    }

    // Если разные части текста имеют разные стили подчеркивания
    return undefined;
  }

  // Проверяем стили в формате Fabric.js (obj.styles[line][char])
  if (textObject.styles && typeof textObject.styles === 'object') {
    const underlineStates = new Set<boolean>();
    let hasStyles = false;

    // Если есть выделение, проверяем только стили в выделении
    if (hasSelection) {
      const selectionStart = textObject.selectionStart;
      const selectionEnd = textObject.selectionEnd;

      let lineIndex = 0;
      let charIndex = 0;
      let currentPos = 0;

      // Проходим по всем строкам и символам, чтобы найти стили в выделении
      for (const lineNum in textObject.styles) {
        lineIndex = parseInt(lineNum, 10);
        const line = textObject.styles[lineNum];

        for (const charNum in line) {
          charIndex = parseInt(charNum, 10);
          currentPos = lineIndex * textObject._textLines[lineIndex].length + charIndex;

          // Если символ в пределах выделения
          if (currentPos >= selectionStart && currentPos < selectionEnd) {
            const style = line[charNum];
            if (style && style.underline !== undefined) {
              underlineStates.add(!!style.underline);
              hasStyles = true;
            }
          }
        }
      }

      // Если в выделении нет стилей, используем общий стиль
      if (!hasStyles) {
        return !!textObject.underline;
      }

      // Если все части выделения имеют одинаковый стиль подчеркивания
      if (underlineStates.size === 1) {
        return Array.from(underlineStates)[0];
      }

      // Если разные части выделения имеют разные стили подчеркивания
      return undefined;
    }

    // Если нет выделения, проверяем все стили
    for (const lineNum in textObject.styles) {
      const line = textObject.styles[lineNum];
      for (const charNum in line) {
        const style = line[charNum];
        if (style && style.underline !== undefined) {
          underlineStates.add(!!style.underline);
          hasStyles = true;
        }
      }
    }

    // Если нет специфических стилей, используем общий стиль
    if (!hasStyles) {
      return !!textObject.underline;
    }

    // Если все части текста имеют одинаковый стиль подчеркивания
    if (underlineStates.size === 1) {
      return Array.from(underlineStates)[0];
    }

    // Если разные части текста имеют разные стили подчеркивания
    return undefined;
  }

  // Если нет стилей, возвращаем общий стиль
  return !!textObject.underline;
};

/**
 * Проверяет, является ли текст или выделенная часть текста жирным
 * @param textObject Текстовый объект Fabric.js
 * @returns true, если текст жирный, false - если не жирный, undefined - если текст содержит части с разными стилями
 */
export const isTextBold = (textObject: Textbox): boolean | undefined => {
  // Если объект не является текстом, возвращаем false
  if (!textObject || textObject.type.toLowerCase() !== 'textbox') {
    return false;
  }

  const isBold = (fontWeight: any) => {
    return fontWeight === 'bold' || fontWeight >= 500;
  };

  // Проверяем, есть ли выделение
  const hasSelection = textObject.selectionStart !== textObject.selectionEnd;

  // Если есть стили в формате массива объектов со свойствами start, end, style
  if (Array.isArray(textObject.styles)) {
    // Если есть выделение, проверяем только стили в выделении
    if (hasSelection) {
      const selectionStart = textObject.selectionStart;
      const selectionEnd = textObject.selectionEnd;

      const boldStates = new Set<boolean>();
      let hasStylesInSelection = false;

      textObject.styles.forEach((styleItem: any) => {
        // Проверяем, пересекается ли стиль с выделением
        if (
          styleItem.style &&
          ((styleItem.start <= selectionStart && styleItem.end > selectionStart) ||
            (styleItem.start >= selectionStart && styleItem.start < selectionEnd))
        ) {
          if (styleItem.style.fontWeight !== undefined) {
            boldStates.add(isBold(styleItem.style.fontWeight));
            hasStylesInSelection = true;
          }
        }
      });

      // Если нет стилей в выделении, используем общий стиль
      if (!hasStylesInSelection) {
        return isBold(textObject.fontWeight);
      }

      // Если все части выделения имеют одинаковый стиль жирности
      if (boldStates.size === 1) {
        return Array.from(boldStates)[0];
      }

      // Если разные части выделения имеют разные стили жирности
      return undefined;
    }

    // Если нет выделения, проверяем все стили
    const boldStates = new Set<boolean>();
    let hasStyles = false;

    textObject.styles.forEach((styleItem: any) => {
      if (styleItem.style && styleItem.style.fontWeight !== undefined) {
        boldStates.add(isBold(styleItem.style.fontWeight));
        hasStyles = true;
      }
    });

    // Если нет специфических стилей, используем общий стиль
    if (!hasStyles) {
      return isBold(textObject.fontWeight);
    }

    // Если все части текста имеют одинаковый стиль жирности
    if (boldStates.size === 1) {
      return Array.from(boldStates)[0];
    }

    // Если разные части текста имеют разные стили жирности
    return undefined;
  }

  // Проверяем стили в формате Fabric.js (obj.styles[line][char])
  if (textObject.styles && typeof textObject.styles === 'object') {
    const boldStates = new Set<boolean>();
    let hasStyles = false;

    // Если есть выделение, проверяем только стили в выделении
    if (hasSelection) {
      const selectionStart = textObject.selectionStart;
      const selectionEnd = textObject.selectionEnd;

      let lineIndex = 0;
      let charIndex = 0;
      let currentPos = 0;

      // Проходим по всем строкам и символам, чтобы найти стили в выделении
      for (const lineNum in textObject.styles) {
        lineIndex = parseInt(lineNum, 10);
        const line = textObject.styles[lineNum];

        for (const charNum in line) {
          charIndex = parseInt(charNum, 10);
          currentPos = lineIndex * textObject._textLines[lineIndex].length + charIndex;

          // Если символ в пределах выделения
          if (currentPos >= selectionStart && currentPos < selectionEnd) {
            const style = line[charNum];
            if (style && style.fontWeight !== undefined) {
              boldStates.add(isBold(style.fontWeight));
              hasStyles = true;
            }
          }
        }
      }

      // Если в выделении нет стилей, используем общий стиль
      if (!hasStyles) {
        return isBold(textObject.fontWeight);
      }

      // Если все части выделения имеют одинаковый стиль жирности
      if (boldStates.size === 1) {
        return Array.from(boldStates)[0];
      }

      // Если разные части выделения имеют разные стили жирности
      return undefined;
    }

    // Если нет выделения, проверяем все стили
    for (const lineNum in textObject.styles) {
      const line = textObject.styles[lineNum];
      for (const charNum in line) {
        const style = line[charNum];
        if (style && style.fontWeight !== undefined) {
          boldStates.add(isBold(style.fontWeight));
          hasStyles = true;
        }
      }
    }

    // Если нет специфических стилей, используем общий стиль
    if (!hasStyles) {
      return isBold(textObject.fontWeight);
    }

    // Если все части текста имеют одинаковый стиль жирности
    if (boldStates.size === 1) {
      return Array.from(boldStates)[0];
    }

    // Если разные части текста имеют разные стили жирности
    return undefined;
  }

  // Если нет стилей, возвращаем общий стиль
  return isBold(textObject.fontWeight);
};

/**
 * Проверяет, является ли текст или выделенная часть текста обычным (не жирным и не подчеркнутым)
 * @param textObject Текстовый объект Fabric.js
 * @returns true, если текст обычный, false - если не обычный, undefined - если текст содержит части с разными стилями
 */
export const isTextRegular = (textObject: Textbox): boolean | undefined => {
  // Если объект не является текстом, возвращаем false
  if (!textObject || textObject.type.toLowerCase() !== 'textbox') {
    return false;
  }

  const isBold = isTextBold(textObject);
  const isUnderlined = isTextUnderlined(textObject);

  // Если хотя бы одно из свойств имеет неоднозначное значение (undefined),
  // то общий результат также неоднозначен
  if (isBold === undefined || isUnderlined === undefined) {
    return undefined;
  }

  // Текст считается обычным, если он не жирный и не подчеркнутый
  return !isBold && !isUnderlined;
};

/**
 * Удаляет определенный стиль форматирования текста, сохраняя другие стили
 * @param params Параметры для удаления стиля
 */
export const removeFontStyle = ({
  style,
  canvas,
  targetIds,
  canvasId,
  addBackup,
}: MenuItemWithBackup & { style: 'bold' | 'underline' }) => {
  const canvasObjects = canvas.getObjects();

  targetIds.forEach((id) => {
    const obj = canvasObjects.find((o: FabricObject) => {
      if (o.id === id) {
        return true;
      } else if (o.category) {
        // Если объект в группе (bullets)
        if (o.category.toLowerCase() === 'bullet' && o instanceof Group) {
          performActionOnObjectInGroup(o, id, (obj) => {
            if (obj.type.toLowerCase() === 'textbox' && obj instanceof Textbox) {
              switch (style) {
                case 'bold':
                  // Убираем жирность, сохраняя другие стили
                  obj.set({
                    fontWeight: 'normal',
                  });

                  // Обрабатываем obj.styles (стандартный формат Fabric.js)
                  Object.keys(obj.styles).forEach((key) => {
                    const s = obj.styles[key];
                    Object.values(s).forEach((styleObj: any) => {
                      if (styleObj.fontWeight) delete styleObj.fontWeight;
                    });
                  });

                  // Обрабатываем массив стилей с полями start, end, style
                  if (Array.isArray(obj.styles)) {
                    obj.styles.forEach((styleItem: any) => {
                      if (styleItem.style && styleItem.style.fontWeight) {
                        delete styleItem.style.fontWeight;
                      }
                    });
                  }
                  obj.dirty = true;
                  break;

                case 'underline':
                  // Убираем подчеркивание, сохраняя другие стили
                  obj.set({
                    underline: false,
                  });

                  // Обрабатываем obj.styles (стандартный формат Fabric.js)
                  Object.keys(obj.styles).forEach((key) => {
                    const s = obj.styles[key];
                    Object.values(s).forEach((styleObj: any) => {
                      if (styleObj.underline) delete styleObj.underline;
                    });
                  });

                  // Обрабатываем массив стилей с полями start, end, style
                  if (Array.isArray(obj.styles)) {
                    obj.styles.forEach((styleItem: any) => {
                      if (styleItem.style && styleItem.style.underline) {
                        delete styleItem.style.underline;
                      }
                    });
                  }
                  obj.dirty = true;
                  break;
              }
            }
          });
          return; // Объект найден и обработан
        }
      }
      return false;
    });

    if (obj && obj.type.toLowerCase() === 'textbox') {
      try {
        if (obj instanceof Textbox) {
          if (obj.selectionStart === obj.selectionEnd) {
            // Нет выделения -> применяем стиль ко всему тексту
            switch (style) {
              case 'bold':
                // Убираем жирность, сохраняя другие стили
                obj.set({
                  fontWeight: 'normal',
                });

                // Обрабатываем obj.styles (стандартный формат Fabric.js)
                Object.keys(obj.styles).forEach((key) => {
                  const s = obj.styles[key];
                  Object.values(s).forEach((styleObj: any) => {
                    if (styleObj.fontWeight) delete styleObj.fontWeight;
                  });
                });

                // Обрабатываем массив стилей с полями start, end, style
                if (Array.isArray(obj.styles)) {
                  obj.styles.forEach((styleItem: any) => {
                    if (styleItem.style && styleItem.style.fontWeight) {
                      delete styleItem.style.fontWeight;
                    }
                  });
                }
                obj.dirty = true;
                break;

              case 'underline':
                // Убираем подчеркивание, сохраняя другие стили
                obj.set({
                  underline: false,
                });

                // Обрабатываем obj.styles (стандартный формат Fabric.js)
                Object.keys(obj.styles).forEach((key) => {
                  const s = obj.styles[key];
                  Object.values(s).forEach((styleObj: any) => {
                    if (styleObj.underline) delete styleObj.underline;
                  });
                });

                // Обрабатываем массив стилей с полями start, end, style
                if (Array.isArray(obj.styles)) {
                  obj.styles.forEach((styleItem: any) => {
                    if (styleItem.style && styleItem.style.underline) {
                      delete styleItem.style.underline;
                    }
                  });
                }
                obj.dirty = true;
                break;
            }
          } else {
            // Есть выделение -> применяем стиль только к выделенной части
            const selectionStyles: any = {};
            switch (style) {
              case 'bold':
                selectionStyles.fontWeight = 'normal';
                break;
              case 'underline':
                selectionStyles.underline = false;
                break;
            }
            obj.setSelectionStyles(selectionStyles, obj.selectionStart, obj.selectionEnd);

            // Обрабатываем массив стилей с полями start, end, style для выделенного текста
            if (Array.isArray(obj.styles)) {
              const start = obj.selectionStart;
              const end = obj.selectionEnd;

              // Проверяем, есть ли уже стиль для этого диапазона
              const existingStyleIndex = obj.styles.findIndex(
                (styleItem: any) => styleItem.start === start && styleItem.end === end,
              );

              if (existingStyleIndex !== -1) {
                // Обновляем существующий стиль
                switch (style) {
                  case 'bold':
                    if (obj.styles[existingStyleIndex].style.fontWeight) {
                      delete obj.styles[existingStyleIndex].style.fontWeight;
                    }
                    break;
                  case 'underline':
                    if (obj.styles[existingStyleIndex].style.underline) {
                      delete obj.styles[existingStyleIndex].style.underline;
                    }
                    break;
                }
              } else {
                // Создаем новый стиль для выделенного диапазона
                const newStyle: any = { start, end, style: {} };
                switch (style) {
                  case 'bold':
                    newStyle.style.fontWeight = 'normal';
                    break;
                  case 'underline':
                    newStyle.style.underline = false;
                    break;
                }
                obj.styles.push(newStyle);
              }
            }

            obj.dirty = true;
          }
        }
      } catch (e) {
        console.error('⛔ Error removing font style', e);
      }
    }
  });

  addBackup(canvasId, canvas.toJSON());
  canvas.renderAll();
};

export const getActiveColorFromTextObject = (textObject: Textbox) => {
  const activeColor = textObject.get('fill') || textObject.get('stroke');
  return activeColor;
};
