/* eslint-disable @typescript-eslint/no-explicit-any */
import { Canvas, FabricObject, ActiveSelection, IText } from 'fabric';

import { useCanvasStore } from '../store/canvas';

import { isObjectLocked } from './lockObjects';

type DirectionConfiguration = { axis: 'top' | 'left'; delta: number };

interface KeyboardMoveConfig {
  canvas: Canvas;
  step?: number;
  altStep?: number;
  event?: KeyboardEvent;
}

interface MoveResult {
  objectsMoved: boolean;
  objects: FabricObject[];
  direction: string;
}

/**
 * Moves objects on the canvas using arrow keys
 * @param direction - Movement direction ('ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight')
 * @param config - Movement configuration
 * @returns Information about moved objects
 */
export const moveObjectsWithKeyboard = (
  direction: string,
  { canvas, step = 1, altStep = 10, event }: KeyboardMoveConfig,
): MoveResult => {
  if (!canvas) {
    return { objectsMoved: false, objects: [], direction };
  }

  // Get active objects that are not locked and not in text editing mode
  const activeObjects = canvas
    .getActiveObjects()
    .filter((obj) => !isObjectLocked(obj) && !(obj instanceof IText && obj.isEditing));

  if (activeObjects.length === 0) {
    return { objectsMoved: false, objects: [], direction };
  }

  // Determine movement step (regular or with Alt key pressed)
  const moveStep = event?.altKey ? altStep : step;

  // Map of movement directions
  const directions: Record<string, DirectionConfiguration> = {
    ArrowUp: { axis: 'top', delta: -moveStep },
    ArrowDown: { axis: 'top', delta: moveStep },
    ArrowLeft: { axis: 'left', delta: -moveStep },
    ArrowRight: { axis: 'left', delta: moveStep },
  };

  // Get configuration for the specified direction
  const targetDirection = directions[direction];
  if (!targetDirection) {
    return { objectsMoved: false, objects: [], direction };
  }

  // Function to move a single object
  const moveObject = (obj: FabricObject, { axis, delta }: DirectionConfiguration) => {
    obj.set(axis, obj[axis] + delta);
    obj.setCoords();
    obj.fire('moving');
  };

  // Move each object
  activeObjects.forEach((obj) =>
    moveObject(obj, { axis: targetDirection.axis, delta: targetDirection.delta }),
  );

  // If more than one object is moved, update the selection
  if (activeObjects.length > 1) {
    const activeSelection = new ActiveSelection(activeObjects);
    canvas.setActiveObject(activeSelection);
  }

  // Update the canvas
  canvas.requestRenderAll();

  return { objectsMoved: true, objects: activeObjects, direction };
};

/**
 * Event handler for keyboard navigation of objects on the canvas
 * @param event Keyboard event
 * @param canvasId ID of the active canvas
 * @param canvas Canvas instance
 * @param updateContextMenu Function to update the context menu
 * @returns true if the event was handled
 */
export const handleArrowKeyNavigation = (
  event: KeyboardEvent,
  canvasId: string,
  canvas: Canvas,
  updateContextMenu: () => void,
): boolean => {
  // Get access to the store
  const { updateMovementState, finishObjectMovement } = useCanvasStore.getState();

  // Directions we handle
  const supportedDirections = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

  if (!supportedDirections.includes(event.code)) {
    return false;
  }

  event.preventDefault();

  // If this is a key release event, do nothing
  if (event.type === 'keyup') {
    return true;
  }

  // Move objects
  const { objectsMoved, objects, direction } = moveObjectsWithKeyboard(event.code, {
    canvas,
    event,
  });

  if (objectsMoved) {
    // Check if the direction of movement has changed
    const isDirectionChanged = updateMovementState(canvasId, objects, direction);

    // If the direction has changed, create a backup
    if (isDirectionChanged) {
      finishObjectMovement(canvasId, true);
    }

    // Update the context menu
    updateContextMenu();
    return true;
  }

  return false;
};

/**
 * Check if a backup needs to be created when selection changes
 * @param canvasId Canvas ID
 */
export const checkSelectionAndCreateBackup = (canvasId: string): void => {
  // Get access to the store
  const { checkActiveObjectsChanged, finishObjectMovement } = useCanvasStore.getState();

  // Check if the object selection has changed
  if (checkActiveObjectsChanged(canvasId)) {
    // Create a backup if there were changes
    finishObjectMovement(canvasId, true);
  }
};
