import { createApi } from '@reduxjs/toolkit/query/react';

import { serverPath } from '@app/utils/server-path';
import { CommonResponse } from '@app/types/common.type';
import { CommonInit, UserData } from '@app/types/user.type';
import { ProductInit } from '@app/types/product.type';
import type { GetThemeInitResponse } from '@app/types/presentation.type';

import { updateUserData } from '@app/store/slice/user.slice';

import { cookie } from '@app/app/lib/cookie';

import { logout } from '../slice/auth.slice';

import { appBaseQueryWithResend } from './base-query';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: appBaseQueryWithResend,
  tagTypes: ['INIT_USER', 'INIT_COMMON', 'INIT_THEME', 'INIT_PRODUCT'],
  endpoints: (build) => ({
    login: build.mutation<CommonResponse<null>, { body: FormData }>({
      query: ({ body }) => ({
        url: serverPath.user.login,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
      invalidatesTags: ['INIT_USER'],
    }),
    logOut: build.mutation<CommonResponse<boolean>, void>({
      query: () => ({
        url: serverPath.user.logout,
      }),
      invalidatesTags: ['INIT_COMMON', 'INIT_THEME', 'INIT_PRODUCT'],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          cookie.delete('uid', '/');
          dispatch(userApi.util.resetApiState());
          window.localStorage.clear();
          cookie.deleteWithDomain('email_hash', '/', window.location.hostname);
          sessionStorage.removeItem('emailNotifyShown');
          dispatch(updateUserData(null));
          dispatch(logout());
        } catch (error) {
          console.error('Logout failed:', error);
        }
      },
    }),
    getUserInit: build.query<CommonResponse<UserData>, null>({
      query: () => ({
        url: serverPath.init.user,
      }),
      providesTags: ['INIT_USER'],
    }),
    getCommonInit: build.query<CommonResponse<CommonInit>, null>({
      query: () => ({
        url: serverPath.init.common,
      }),
      providesTags: ['INIT_COMMON'],
    }),
    // TODO: Not userApi?
    getThemeInit: build.query<GetThemeInitResponse, void>({
      query: () => ({
        url: serverPath.init.theme,
      }),
      providesTags: ['INIT_THEME'],
    }),
    // TODO: Not userApi?
    getProductInit: build.query<CommonResponse<ProductInit>, null>({
      query: () => ({
        url: serverPath.init.product,
      }),
      providesTags: ['INIT_PRODUCT'],
    }),
    changePassword: build.mutation<CommonResponse<boolean>, { body: FormData }>({
      query: ({ body }) => ({
        url: serverPath.user.password,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['INIT_USER'],
    }),
    updateOptions: build.mutation<CommonResponse<boolean>, { body: FormData }>({
      query: ({ body }) => ({
        url: serverPath.user.updateOptions,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['INIT_USER'],
    }),
    // Добавление кастомного цвета в пресет пользователя
    addCustomColor: build.mutation({
      query: (body) => ({
        url: `${serverPath.setColor}`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (variables, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          const newColor = JSON.parse(data.result);

          dispatch(
            userApi.util.updateQueryData('getThemeInit', undefined, (draft) => {
              if (draft?.result?.['user-preset']) {
                const userPreset = draft.result['user-preset'];

                const colorExists = userPreset.color.some(
                  (color) => color.id === newColor.id || color.value === newColor.value,
                );

                if (!colorExists) {
                  userPreset.color.push({
                    id: newColor.id,
                    value: newColor.value,
                    text_color: ['000000'], //TODO: попросить бека, чтобы это тоже отправлялось, сейчас пока заглушка. Это не используется по итогу при отправке цвета, но в будущем может потребоваться.
                  });
                }
              }
            }),
          );
        } catch (error) {
          console.error('Error adding custom color:', error);
        }
      },
    }),
    // Удаление кастомного цвета
    deleteCustomColor: build.mutation({
      query: ({ preset_id }) => ({
        url: `${serverPath.preset.deletePreset.replace(/\/$/, '')}/${preset_id}`,
        method: 'GET', // Оставляем GET согласно бэку
      }),
      onQueryStarted: async ({ preset_id }, { dispatch, queryFulfilled }) => {
        // Оптимистичное обновление состояния
        const patchResult = dispatch(
          userApi.util.updateQueryData('getThemeInit', undefined, (draft) => {
            if (draft?.result?.['user-preset']) {
              const userPreset = draft.result['user-preset'];
              // Удаляем цвет с указанным preset_id
              userPreset.color = userPreset.color.filter((color) => color.id !== preset_id);
            }
          }),
        );

        try {
          // Ожидаем завершения запроса
          await queryFulfilled;
        } catch (error) {
          console.error('Error deleting custom color:', error);
          // Если произошла ошибка, откатим оптимистическое обновление
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetCommonInitQuery,
  useGetUserInitQuery,
  useLazyGetUserInitQuery,
  useGetThemeInitQuery,
  useGetProductInitQuery,
  useLoginMutation,
  useLogOutMutation,
  useChangePasswordMutation,
  useUpdateOptionsMutation,
  useAddCustomColorMutation,
  useDeleteCustomColorMutation,
} = userApi;
export default userApi;
