import { create } from 'zustand';

interface TextRestorationState {
  showModal: boolean;
  currentTextId: string | null;
  currentText: string;
  currentCanvasId: string | null;
  restoreCallback: (() => void) | null;
}

interface TextRestorationActions {
  openModal: (textId: string, text: string, canvasId: string, callback: () => void) => void;
  closeModal: () => void;
  executeRestore: () => void;
}

type TextRestorationStore = TextRestorationState & TextRestorationActions;

export const useTextRestorationStore = create<TextRestorationStore>((set, get) => ({
  showModal: false,
  currentTextId: null,
  currentText: '',
  currentCanvasId: null,
  restoreCallback: null,

  openModal: (textId, text, canvasId, callback) => {
    set({
      showModal: true,
      currentTextId: textId,
      currentText: text,
      currentCanvasId: canvasId,
      restoreCallback: callback,
    });
  },

  closeModal: () => {
    set({
      showModal: false,
    });
  },

  executeRestore: () => {
    const { restoreCallback } = get();

    if (restoreCallback) {
      restoreCallback();
    }

    set({
      showModal: false,
    });
  },
}));
