// TODO: Finish refactoring
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

import { useForm } from 'react-hook-form';

import { useChangePasswordMutation, useUpdateOptionsMutation } from '@app/store/api/user.api';
import { Dialog, DialogContent, DialogTitle } from '@app/shared/ui/dialog';
import { ChangesSavedPopup } from '@app/widgets/header/ui/modals/change-saved-popup';
import { Button } from '@app/shared/ui/button';

import { PasswordInput } from '../../../components/ui';
import t from '../../../../lib/lng';

import {
  cvaCheckboxContainer,
  cvaContainer,
  cvaInputs,
  cvaSubmitButton,
  cvaSubtitle,
  cvaTitle,
} from './settings-dialog-styles';

type SettingsFields = {
  old_password: string;
  new_password1: string;
  new_password2: string;
  experimental_features: boolean;
};

type SettingsProps = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  experimental_features: boolean;
  isShowExperimental: boolean;
};

const LNG = {
  errors: {
    requires_field: 'Field is required',
  },
};

export const SettingsDialog: FC<SettingsProps> = (props) => {
  const { isOpen, setOpen, experimental_features = false, isShowExperimental } = props;

  const [isSaved, setIsSaved] = useState(false);

  const [updateOptions, { isLoading: isLoadingUpdateOptions }] = useUpdateOptionsMutation();
  const [changePassword, { isLoading: isLoadingChangePassword }] = useChangePasswordMutation();

  const handleCloseAll = (savedStatus: boolean) => {
    setIsSaved(savedStatus);
    setOpen(false);
  };

  const settingsRef = useRef<HTMLDivElement | null>(null);
  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
    register,
  } = useForm<SettingsFields>({
    defaultValues: {
      experimental_features,
    },
  });

  useEffect(() => {
    if (settingsRef?.current && window.innerHeight < Number(settingsRef?.current?.scrollHeight)) {
      new PerfectScrollbar(settingsRef.current, {});
    }
  }, []);

  function handleSubmitChangePassword(data: SettingsFields) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'experimental_features') {
        formData.append(key, String(value));
      }
    });
    changePassword({ body: formData })
      .unwrap()
      .then((resultOfChangePassword) => {
        if (resultOfChangePassword?.status) {
          handleCloseAll(true);
        } else {
          if (resultOfChangePassword.errors) {
            if (
              typeof resultOfChangePassword.errors === 'object' &&
              '__all__' in resultOfChangePassword.errors
            ) {
              setError('old_password', { message: resultOfChangePassword.errors.__all__?.[0] });
            }
            if (typeof resultOfChangePassword.errors === 'string') {
              setError('old_password', { message: resultOfChangePassword.errors });
            }
          }
        }
      });
  }

  function saveDataStorage() {
    const formData = new FormData();
    formData.set('experimental_features', String(watch().experimental_features));
    updateOptions({ body: formData })
      .unwrap()
      .then(() => {
        handleCloseAll(true);
      });
  }

  return (
    <>
      {isSaved && (
        <ChangesSavedPopup
          close={() => {
            setIsSaved(false);
          }}
        />
      )}

      <Dialog open={isOpen} onOpenChange={(open) => setOpen(open)}>
        <DialogContent className="max-w-[50rem]">
          <div className={cvaContainer()} ref={settingsRef}>
            <DialogTitle className={cvaTitle()} asChild>
              <h2>{t.Settings}</h2>
            </DialogTitle>

            <form onSubmit={handleSubmit(handleSubmitChangePassword)}>
              <h3 className={cvaSubtitle()}>{t.changePassword}</h3>

              <div className={cvaInputs()}>
                <PasswordInput
                  {...register('old_password', {
                    required: { value: true, message: LNG.errors.requires_field },
                  })}
                  error={errors.old_password?.message}
                  label={t.oldPassword}
                  onChange={(value) => setValue('old_password', value)}
                />
                <PasswordInput
                  {...register('new_password1', {
                    required: {
                      value: true,
                      message: LNG.errors.requires_field,
                    },
                  })}
                  error={errors.new_password1?.message}
                  label={t.newPassword}
                  onChange={(value) => setValue('new_password1', value)}
                />
                <PasswordInput
                  {...register('new_password2', {
                    required: {
                      value: true,
                      message: LNG.errors.requires_field,
                    },
                  })}
                  error={errors.new_password2?.message}
                  label={t.repeatPassword}
                  onChange={(value) => setValue('new_password2', value)}
                />
              </div>

              <Button
                size="large"
                type="submit"
                disabled={isLoadingChangePassword}
                isFullWidth
                className={cvaSubmitButton()}
              >
                {t.changePassword}
              </Button>

              {isShowExperimental && (
                <>
                  <h3 className={cvaSubtitle()}>{t.EnableExperimentalFeatures}</h3>
                  <label className={cvaCheckboxContainer()}>
                    <input
                      type="checkbox"
                      {...register('experimental_features')}
                      style={{ display: 'none' }}
                      checked={watch().experimental_features}
                    />
                    <div className="m_settings_checkbox m_settings_checkbox-exp" />

                    <div className="m_settings_checkbox_label">
                      {t.experimentalFeaturesCheckboxLabel}
                    </div>
                  </label>

                  <Button
                    size="large"
                    onClick={saveDataStorage}
                    disabled={isLoadingUpdateOptions}
                    isFullWidth
                    className={cvaSubmitButton()}
                  >
                    {t.Save}
                  </Button>
                </>
              )}
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
