import { FabricObject, FabricImage } from 'fabric';

const originalToObject = FabricObject.prototype.toObject;

FabricObject.prototype.toObject = function (additionalProperties: string[] = []) {
  return {
    ...originalToObject.call(this, additionalProperties),
    id: this.id,
    name: this.name,
    layer: this.layer,
    zIndex: this.zIndex,
    category: this.category,
    idGroup: this.idGroup,
    slide_width: this.slide_width,
    slide_height: this.slide_height,
    source_width: this.source_width,
    source_height: this.source_height,
    backgroundColor: this.backgroundColor,
    backgroundImage: this.backgroundImage,
    noCopy: this.noCopy,
    icons: this.icons,
    shapes: this.shapes,
    isNeedScaleImgForFirstRender: this.isNeedScaleImgForFirstRender,
    available_images: this.available_images,
    shape_type: this.shape_type,
    media_type: this.media_type,
    clip_path: this.clip_path,
    source_ext: this.source_ext,
    margin: this.margin,
    space: this.space,
    inner_id: this.inner_id,
    imageColor: this.imageColor,
    originalOpacity: this.originalOpacity,
    isOpacityChangeFromSelection: this.isOpacityChangeFromSelection,
    isLocked: this.isLocked,
    crop: this.crop,
    originalLockState: this.originalLockState,
    alternative_text: this.alternative_text,
    is_truncated: this.is_truncated,
    extra_info: this.extra_info,
  };
};

FabricImage.prototype.objectCaching = true;
