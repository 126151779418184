import { Image, Canvas, Path } from 'fabric';

import { MenuItemWithBackup } from '../types/types';

import { generateObjectId } from './generateId';
import { loadObjectsWithLayers } from './helpers';

interface ImageInfo {
  preview: string;
  image: string;
  source_ext: string;
}

export const scaleImage = async (imageObject: Image, targetWidth: number, targetHeight: number) => {
  const originalWidth = imageObject._originalElement.width;
  const originalHeight = imageObject._originalElement.height;

  // Scale image
  const scaleX = targetWidth / originalWidth;
  const scaleY = targetHeight / originalHeight;
  const imageMaxScale = Math.max(scaleX, scaleY);

  imageObject.width = originalWidth;
  imageObject.height = originalHeight;

  const clipPath = imageObject.clipPath as Path;

  if (clipPath) {
    const thumbScale = Math.min(1024 / originalWidth, 1024 / originalHeight);
    const thumbWidth = originalWidth * thumbScale;
    const thumbHeight = originalHeight * thumbScale;
    const scale = Math.min(targetWidth / thumbWidth, targetWidth / thumbHeight);

    clipPath.scaleX = 1 / scale;
    clipPath.scaleY = 1 / scale;
  }

  imageObject.scale(imageMaxScale);

  imageObject.isNeedScaleImgForFirstRender = false;
  // imageObject.objectCaching = false;
  // imageObject.dirty = true;
  // imageObject.clipPath.width = imageObject.clipPath.width / scaleX;
  // imageObject.clipPath.height = imageObject.clipPath.height / scaleY;
};

export const changeImage = async ({
  canvas,
  canvasId,
  newImageSrc: src,
  sourceExt,
  addBackup,
}: Omit<MenuItemWithBackup, 'closeOnClick' | 'item'> & {
  newImageSrc: string;
  sourceExt: string;
}) => {
  const activeObject = canvas.getActiveObject();
  if (activeObject) {
    if (activeObject.type.toLowerCase() === 'image') {
      const targetWidth = activeObject.width * activeObject.scaleX;
      const targetHeight = activeObject.height * activeObject.scaleY;

      const maxSize = Math.max(targetWidth, targetHeight);

      //Remove img
      canvas.remove(activeObject);

      const img = await Image.fromURL(src, {
        crossOrigin: 'anonymous',
      });

      const imgToPasteWidth = img._originalElement.width;
      const imgToPasteHeight = img._originalElement.height;

      const scaleImgToPaste = Math.min(maxSize / imgToPasteWidth, maxSize / imgToPasteHeight);

      scaleImage(img, imgToPasteWidth * scaleImgToPaste, imgToPasteHeight * scaleImgToPaste);

      //TODO: add work with mask img
      img.set({
        angle: activeObject.angle,
        left: activeObject.left,
        top: activeObject.top,
        category: activeObject.category,
        media_type: activeObject.media_type,
        id: generateObjectId(canvasId),
        originX: activeObject.originX,
        originY: activeObject.originY,
        noCopy: activeObject.noCopy,
        available_images: activeObject.available_images,
        source_ext: sourceExt,
        layer: activeObject.layer,
        zIndex: activeObject.zIndex,
        name: activeObject.name,
        clip_path: activeObject.clip_path,
        clipPath: activeObject.clipPath,
        source_width: activeObject.source_width,
        source_height: activeObject.source_height,
        inner_id: activeObject.inner_id,
        crop: activeObject.crop,
      });

      canvas.add(img);
    }
    addBackup(canvasId, canvas.toJSON());
    loadObjectsWithLayers(canvas, canvasId, canvas.toJSON()).then(() => {
      canvas.requestRenderAll();
    });
  }
};

/**
 * Add new image to canvas
 * @param {Object} params - Parameters for adding an image
 * @param {Canvas} params.canvas - Canvas instance
 * @param {string} params.canvasId - Canvas ID
 * @param {string} params.imageSrc - URL of the image to add
 * @param {string} params.sourceExt - Extension of the original image file
 * @param {string} params.mediaType - Media type ('object' or 'photo' or another)
 * @param {function} params.addBackup - Function to create a backup
 * @param {Array} params.availableImages - Array of available images
 * @param {string} params.layer - Layer of the image
 * @returns {Promise<Image>} Promise that resolves with the added image or rejects with an error
 */
export const addNewImage = ({
  canvas,
  canvasId,
  imageSrc,
  sourceExt = '',
  addBackup,
  availableImages = [],
  mediaType,
  layer = 'background',
}: {
  canvas: Canvas;
  canvasId: string;
  imageSrc: string;
  sourceExt?: string;
  mediaType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addBackup: (canvasId: string, data: any) => void;
  availableImages?: ImageInfo[];
  layer?: string;
}): Promise<Image> => {
  return new Promise((resolve, reject) => {
    try {
      const canvasWidth = canvas.getWidth();
      const canvasHeight = canvas.getHeight();

      Image.fromURL(imageSrc, { crossOrigin: 'anonymous' })
        .then((fabricImage) => {
          try {
            const width = fabricImage.width || 100;
            const height = fabricImage.height || 100;

            const scaleFactor = Math.min(
              (canvasWidth * 0.5) / width,
              (canvasHeight * 0.5) / height,
            );

            fabricImage.set({
              id: generateObjectId(canvasId),
              left: width / 2,
              top: height / 2,
              media_type: mediaType,
              source_ext: sourceExt,
              available_images: availableImages,
              crop: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              },
              inner_id: '', //need for backend
              layer,
              scaleX: scaleFactor,
              scaleY: scaleFactor,
              name: `image_${Date.now()}`,
              zIndex: 1,
              isNeedScaleImgForFirstRender: false,
              originX: 'center',
              originY: 'center',
            });

            // Add image to canvas
            canvas.add(fabricImage);

            // Make image active and render before creating backup
            canvas.setActiveObject(fabricImage);
            canvas.requestRenderAll();

            // Create backup
            addBackup(canvasId, canvas.toJSON());

            // Сохраняем ID изображения для дальнейшего использования
            const imageId = fabricImage.id;

            loadObjectsWithLayers(canvas, canvasId, canvas.toJSON()).then(() => {
              // Найдем объект с тем же ID и сделаем его активным
              const objects = canvas.getObjects();
              const newImageObject = objects.find((obj) => obj.id === imageId);

              if (newImageObject) {
                requestAnimationFrame(() => {
                  canvas.setActiveObject(newImageObject);
                  canvas.requestRenderAll();
                });
              }
            });

            resolve(fabricImage);
          } catch (innerError) {
            reject(innerError);
          }
        })
        .catch((error) => {
          console.error('Error loading image:', error);
          reject(error);
        });
    } catch (error) {
      console.error('Error adding new image to canvas:', error);
      reject(error);
    }
  });
};
