import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';

import { serverPath } from '@app/utils/server-path';
import { CreateDraft, Presentation, ProcessResponse } from '@app/types/presentation.type';
import { CommonResponse } from '@app/types/common.type';
import { xhrHeaders } from '@lib/utils';

import globalRequestBlocker from '../global-request-blocker';
import { updatePresentation, uploadFileProgress } from '../slice/presentation.slice';

import { appBaseQueryWithResend } from './base-query';

const presentationApi = createApi({
  reducerPath: 'presentationApi',
  tagTypes: ['FILE_UPLOAD', 'PRESENTATION', 'PRESENTATIONS'],
  baseQuery: appBaseQueryWithResend,
  endpoints: (build) => ({
    uploadPPTX: build.mutation({
      queryFn: async ({ url, data }: { url: string; data: FormData }, api) => {
        const response = await axios
          .post(url, data, {
            headers: { ...xhrHeaders() },
            onUploadProgress: (processEvent) => {
              api.dispatch(
                uploadFileProgress({
                  uploadProgress: Math.round(
                    (processEvent.loaded / (processEvent?.total ?? 1)) * 100,
                  ),
                }),
              );
            },
          })
          .then((response) => response)
          .catch((error: AxiosError) => {
            console.error('[UPLOAD ERROR]: ', error);
            return { data: { error: error.message, status: error.status } };
          });
        return response;
      },
    }),
    uploadLogo: build.mutation({
      query: ({ body }: { body: FormData }) => ({
        url: `${serverPath.uploadLogo}`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
    }),
    deleteLogo: build.query({
      query: ({ id }: { id: number }) => ({
        url: `${serverPath.deleteLogo}${id}`,
      }),
    }),
    downloadPresentation: build.query({
      query: ({ projectId }: { projectId: number }) => ({
        url: `${serverPath.download}${projectId}/`,
      }),
    }),
    downloadPresentationPing: build.query({
      query: ({ projectId }: { projectId: number }) => ({
        url: `${serverPath.downloadPing}${projectId}/`,
        responseHandler: async (response) => {
          if (
            response.headers.get('Content-Type') ===
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
          ) {
            return await response.blob();
          } else if (response.headers.get('Content-Type') === 'application/json') {
            return response.json();
          } else return false;
        },
        cache: 'no-cache',
      }),
    }),
    updatePresentationStyle: build.mutation<
      ProcessResponse,
      { projectId: number; body: FormData; isWorkspaceVariant?: boolean; workspaceId?: string }
    >({
      query: ({ projectId, body, isWorkspaceVariant = false, workspaceId }) => ({
        url:
          isWorkspaceVariant && workspaceId
            ? `${serverPath.workspace.process}${workspaceId}/`
            : `${serverPath.process}${projectId}/`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        formData: true,
      }),
      invalidatesTags: ['PRESENTATION', 'PRESENTATIONS'],
      async onQueryStarted(_, { dispatch }) {
        globalRequestBlocker({ dispatch });
      },
    }),
    /* REMOVE PRESENTATION FROM PRESENTATIONS */
    removePresentation: build.mutation<CommonResponse<null>, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `${serverPath.projects}${projectId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PRESENTATIONS'],
    }),
    /* CLONE FULL PRESENTATION */
    copyPresentation: build.query<CommonResponse<null>, { projectId: string }>({
      query: ({ projectId }) => ({
        url: `${serverPath.projects}${projectId}/`,
      }),
    }),
    /* RENAME PRESENTATION */
    renamePresentation: build.mutation<CommonResponse<null>, { projectId: string; body: FormData }>(
      {
        query: ({ projectId, body }) => ({
          url: `${serverPath.projects}${projectId}/`,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          formData: true,
        }),
        async onQueryStarted(patch, { dispatch, queryFulfilled }) {
          globalRequestBlocker({ dispatch });
          queryFulfilled.then(({ data }) => {
            if (data.status) {
              presentationApi.util.updateQueryData(
                'loadPresentation',
                { projectId: String(patch.projectId) },
                (res) => res,
              );
            } else {
              console.error('[renamePresentation api]: error create rename');
            }
          });
        },
        invalidatesTags: ['PRESENTATION', 'PRESENTATIONS'],
      },
    ),
    fileDownLoad: build.query<{ status: boolean }, string>({
      query: (fileUrl: string) => ({
        url: fileUrl,
      }),
      providesTags: ['FILE_UPLOAD'],
    }),
    createDraft: build.query<CreateDraft, void>({
      query: () => ({
        url: serverPath.draft.draftCreate,
      }),
      async onQueryStarted(patch, { queryFulfilled }) {
        queryFulfilled.then(({ data: presentation }) => {
          if (presentation.status && presentation.result) {
            presentationApi.util.updateQueryData(
              'loadPresentation',
              { projectId: String(presentation.result.project_id) },
              (res) => res,
            );
          } else {
            setTimeout(
              () => presentationApi.util.updateQueryData('createDraft', patch, (res) => res),
              2000,
            );
            console.error('[Create craft api]: error create draft');
          }
        });
      },
      providesTags: ['PRESENTATION', 'PRESENTATIONS'],
    }),
    loadPresentation: build.query<Presentation, { projectId: string; projectLink?: string }>({
      query: ({ projectId }) => ({
        url: `${serverPath.queuePing}${projectId}/`,
      }),
      providesTags: ['PRESENTATION'],
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        globalRequestBlocker({ dispatch, status: true });
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              updatePresentation({
                projectId: Number(patch.projectId),
                projectLink: patch.projectId,
                presentation: data.result,
              }),
            );
          })
          .finally(() => {
            globalRequestBlocker({ dispatch, status: false });
          });
      },
    }),
    // TODO: Type is incorrect { files: Array<(Fix this)> }
    loadPresentations: build.query<{ files: Array<Presentation['result']> }, null>({
      query: () => ({
        url: serverPath.projects,
      }),
      providesTags: ['PRESENTATIONS'],
    }),
    sharePresentation: build.mutation<
      CommonResponse<{ short_url: string }>,
      { correctUrl: string; method: 'POST' | 'GET' | 'DELETE'; body?: FormData }
    >({
      query: ({ correctUrl, body, method }) => ({
        url: correctUrl,
        method,
        body,
        formData: true,
      }),
      invalidatesTags: ['PRESENTATION', 'PRESENTATIONS'],
    }),
  }),
});

export const {
  useLoadPresentationQuery,
  useLazyCreateDraftQuery,
  useLazyLoadPresentationQuery,
  useLazyFileDownLoadQuery,
  useRenamePresentationMutation,
  useUpdatePresentationStyleMutation,
  useLazyDownloadPresentationQuery,
  useLazyDownloadPresentationPingQuery,
  useLazyDeleteLogoQuery,
  useUploadLogoMutation,
  useUploadPPTXMutation,
  useLoadPresentationsQuery,
  useSharePresentationMutation,
  useRemovePresentationMutation,
  useLazyCopyPresentationQuery,
} = presentationApi;
export default presentationApi;
