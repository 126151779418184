import { FC, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { route_path } from '@app/utils/route_path';

import { WithoutHeaderLayout } from '@app/components/layout';

import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CommonOutlet, LoginPage, MainPage, RedirectPaymentPage } from '../pages';

import { analytics } from './analytics';

import { DefaultLayout, StageLayout } from './layouts';

const FinalizeStagePage = lazy(() => import('../pages/finalize-stage'));
const SharingPage = lazy(() => import('../pages/sharing'));
const CreateWithAiPage = lazy(() => import('../pages/create-with-ai'));
const CreateWithAiPreviewPage = lazy(() => import('../pages/create-with-ai-preview'));
const CreateStartPage = lazy(() => import('../pages/create'));
const LegalPage = lazy(() => import('../pages/legal/legal.page'));
const TermsPage = lazy(() => import('../pages/legal/terms.page'));
const PrivacyPage = lazy(() => import('../pages/legal/privacy.page'));
const CookiePage = lazy(() => import('../pages/legal/cookie.page'));
const FeaturesPage = lazy(() => import('../pages/features/features.page'));
const AboutPage = lazy(() => import('../pages/about/about.page'));
const Wondercheck = lazy(() => import('../wondercheck/wondercheck.js'));
const PricingPage = lazy(() => import('../pages/pricing/pricing.page'));
const PasswordReset = lazy(() => import('../auth/passwordReset.js'));
const PasswordResetConfirm = lazy(() => import('../auth/passwordResetConfirm.js'));
const RegistrationPage = lazy(() => import('../pages/registration/registration.page.js'));
const Error404Page = lazy(() => import('../pages/error/error404.page'));
const ProjectIdPage = lazy(() => import('../pages/project/project-id.page'));
const ProjectsPage = lazy(() => import('../pages/projects/projects.page'));
const SetupStylePage = lazy(() => import('../pages/create/setup-style.page'));
const UploadDraftPage = lazy(() => import('../pages/create/upload-draft.page'));

type Props = {
  context: unknown;
};

const handleQueryError = (error: unknown): void => {
  console.error('TanStack Query Error:', {
    message: error instanceof Error ? error.message : 'Unknown error',
    timestamp: new Date().toISOString(),
    error,
  });
};

const queryCache = new QueryCache({
  onError: handleQueryError,
});

const mutationCache = new MutationCache({
  onError: handleQueryError,
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
});

// TODO: Migrate to RouterProvider
export const Router: FC<Props> = ({ context }) => {
  const location = useLocation();

  function updateViewport(pathname: string) {
    if (window.innerWidth > 1024 || !pathname) return;
    if (pathname.match(/\/create|files|\/workspace/)) {
      document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=1024');
    } else if (
      !(document.querySelector('meta[name="viewport"]') as HTMLMetaElement)?.content.match(
        /width=device-width/,
      )
    ) {
      document
        .querySelector('meta[name="viewport"]')
        ?.setAttribute('content', 'width=device-width, initial-scale=1');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    updateViewport(location.pathname);
    if (!location.pathname.match(/\/create\//)) window.sessionStorage.removeItem('genState');
    if (!location.pathname.match(/\/create-draft\//))
      window.sessionStorage.removeItem('draftState');

    if (location.pathname.match(/upload-draft/) && !location.search.includes('demo')) {
      analytics.emitEvent('file_upload_open', {
        GTM: {},
        Amplitude: {},
      });
    }
  }, [location.pathname, location.hash]);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={route_path.main} element={<CommonOutlet />}>
          {/* TODO: Refactor */}
          <Route index element={<MainPage />} />
          <Route path={route_path.login} element={<LoginPage />} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error*/}
          <Route path="/register/" element={<RegistrationPage context={context} />} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error*/}
          <Route path="/password-reset/" element={<PasswordReset context={context} />} />
          <Route
            path="/password-reset/:token"
            element={<PasswordResetConfirm context={context} />}
          />

          {/* TODO: Refactor */}
          <Route path={route_path.main} element={<WithoutHeaderLayout />}>
            <Route path={route_path.about} element={<AboutPage />} />
            <Route path={route_path.features} element={<FeaturesPage />} />
          </Route>

          {/***** Default pages *****/}
          <Route path={route_path.main} element={<DefaultLayout />}>
            <Route path={route_path.pricing} element={<PricingPage />} />
            <Route path={route_path.create} element={<CreateStartPage />} />
            <Route
              path={route_path.workspaceCreate}
              element={<CreateStartPage isWorkspaceVariant />}
            />
            {/***** Create workspace *****/}
            <Route path={route_path.workspaceCreate} element={<CreateStartPage />} />
            <Route path={route_path.projects} element={<ProjectsPage />} />
            <Route path={`${route_path.redirectPayment}/*`} element={<RedirectPaymentPage />} />
            <Route path={route_path.notFound} element={<Error404Page />} />
          </Route>

          {/***** Sharing New *****/}
          <Route path="/s/:token" element={<SharingPage />} />

          {/***** Stage *****/}
          <Route path={route_path.main} element={<StageLayout />}>
            {/***** Upload draft page *****/}
            <Route
              path={`${route_path.uploadDraft}`}
              element={<UploadDraftPage isDemo={location.search.includes('demo')} />}
            />

            {/***** Setup styles page *****/}
            <Route path={`${route_path.setupStyle}:projectId/`} element={<SetupStylePage />} />

            {/***** Project page *****/}
            <Route path={`${route_path.project}:projectId/`} element={<ProjectIdPage />} />
          </Route>

          <Route path={route_path.main} element={<StageLayout isWorkspaceVariant />}>
            {/***** Workspace setup styles page *****/}
            <Route
              path={`${route_path.workspaceSetupStyle}:workspaceId/`}
              element={<SetupStylePage isWorkspaceVariant />}
            />
            {/***** Upload draft page workspace *****/}
            <Route
              path={route_path.workspaceUploadDraft}
              element={
                <UploadDraftPage isDemo={location.search.includes('demo')} isWorkspaceVariant />
              }
            />
            {/***** Workspace *****/}
            <Route path={`${route_path.workspace}:workspaceId/`} element={<FinalizeStagePage />} />

            <Route path={route_path.inputContent} element={<CreateWithAiPage />} />
            <Route path={route_path.previewContent} element={<CreateWithAiPreviewPage />} />
          </Route>

          {/***** Wondercheck *****/}
          <Route path="/wondercheck/*" element={<Wondercheck />} />

          {/***** Legal *****/}
          <Route path={route_path.legal} element={<DefaultLayout />}>
            <Route index element={<LegalPage />} />
            <Route path={route_path.legal_terms} element={<TermsPage />} />
            <Route path={route_path.legal_cookie} element={<CookiePage />} />
            <Route path={route_path.legal_privacy} element={<PrivacyPage />} />
          </Route>
        </Route>

        {/***** NotFound *****/}
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </QueryClientProvider>
  );
};
