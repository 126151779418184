import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Content, Overlay, Portal, Description, Title, Close } from '@radix-ui/react-dialog';
import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { useCannyLink } from '@app/hooks/use-canny-link';
import { CANNY_URL } from '@app/utils/constants';
import { route_path } from '@app/utils/route_path';
import { Button } from '@app/shared/ui/button';
import { cvaIcon, cvaScrollbar } from '@app/shared/ui/elements';
import { CommonOutletContext } from '@app/pages/common.outlet';
import { cn } from '@app/app/lib';

import { useAppSelector } from '@app/hooks/use-store';

import {
  BxGridAltIcon,
  CogIcon,
  CreditCardIcon,
  CrossIcon,
  CrownIcon,
  DownloadCloudIcon,
  LogoutIcon,
  PersonIcon,
  UserCircleIcon,
} from '../../../components/ui/icons';
import t from '../../../../lib/lng';

import { useHeaderStore } from '../model/header.store';
import { navLinks, navLinksV2 } from '../model/static-data';

import {
  cvaActionsMenuMobile,
  cvaCloseButton,
  cvaEmail,
  cvaInner,
  cvaMenuContentMobile,
  cvaNavMenuMobile,
  cvaSeparator,
  cvaBadge,
} from './mobile-menu-styles';
import { cvaMenuItem } from './header-styles';

type MobileMenuProps = {
  daysLeft: number | null;
  onLogOut: () => void;
  onClose: () => void;
};

export const MobileMenu: FC<MobileMenuProps> = (props) => {
  const { daysLeft, onLogOut, onClose } = props;

  const { isAuthenticated } = useAppSelector((state) => state.authState);

  const { setEmailNotify, setShowPayments, setShowSettings, user_ctx, context, commonContext } =
    useOutletContext<CommonOutletContext>();

  const product_balance = context?.product_balance;

  const cannyLink = useCannyLink(user_ctx);

  const { toggleHeaderMenuOpen } = useHeaderStore();
  const handleNavigationClick = () => toggleHeaderMenuOpen(false);

  return (
    <Portal>
      <Overlay />
      {/* TODO: Replace font-inter */}
      <Content className={cn(cvaMenuContentMobile(), cvaScrollbar(), 'font-inter')}>
        <div className={cvaInner()}>
          {/* TODO: Accessibility component */}
          <VisuallyHidden>
            <Title />
            <Description />
          </VisuallyHidden>
          <div className={cvaActionsMenuMobile()}>
            {!isAuthenticated ? (
              <>
                <Button
                  variant="outline"
                  size="large"
                  borderRadius="4xl"
                  onClick={handleNavigationClick}
                  asChild
                >
                  <Link to="/register/">{t.Signup}</Link>
                </Button>
                <Link
                  to="/login/"
                  onClick={handleNavigationClick}
                  className={cvaMenuItem({ isDotHidden: true, isFontSize20: true })}
                >
                  <span className="inline-flex gap-x-1.5">
                    <PersonIcon className={cvaIcon()} />
                    <span>{t.Login}</span>
                  </span>
                </Link>
              </>
            ) : (
              <>
                <Button variant="outline" size="large" asChild onClick={onClose} borderRadius="4xl">
                  <Link to="/create/">{t.Create}</Link>
                </Button>
                <NavLink
                  to="/projects/"
                  className={({ isActive }) =>
                    cvaMenuItem({ isActive, isDotHidden: true, isFontSize20: true })
                  }
                  onClick={onClose}
                >
                  <BxGridAltIcon className={cvaIcon()} />
                  <span>My presentations</span>
                </NavLink>
              </>
            )}
          </div>
          <hr className={cvaSeparator()} />
          {isAuthenticated && (
            <>
              <div className={cvaActionsMenuMobile()}>
                {user_ctx?.username && (
                  <div className={cvaEmail()}>
                    {product_balance?.is_active &&
                    !product_balance?.product.is_trial &&
                    product_balance.type === 'subscription' ? (
                      <CrownIcon
                        className={cn(cvaIcon(), 'text-secondary_warning_SI_400_warning')}
                      />
                    ) : (
                      <UserCircleIcon className={cvaIcon()} />
                    )}
                    <span>{user_ctx.username}</span>
                  </div>
                )}

                {!user_ctx?.is_confirmed && (
                  <button type="button" onClick={() => setEmailNotify(true)} className={cvaBadge()}>
                    Confirm your email
                  </button>
                )}

                {!product_balance?.is_active && product_balance?.product && (
                  <div className={cvaBadge()}>
                    {product_balance.product.is_trial
                      ? t.formatString(t.TrialExpiredPlease, <br />)
                      : t.formatString(t.SubscriptionExpiredPlease, <br />)}
                  </div>
                )}

                {/* PayGo Product balance alert */}
                {product_balance?.is_active &&
                  product_balance?.product &&
                  !product_balance?.product.is_trial &&
                  product_balance.type === 'presentation' && (
                    <div className={cvaBadge()}>
                      <DownloadCloudIcon className={cvaIcon()} />
                      <span>
                        Downloads {product_balance.count} out of {product_balance.product.count}
                      </span>
                    </div>
                  )}

                {daysLeft !== null && (
                  <NavLink to="/pricing/" className={cvaBadge()} onClick={onClose}>
                    {daysLeft > 0
                      ? t.formatString(t.trialExpiresInDays, daysLeft)
                      : t.Less1DayTrial}
                  </NavLink>
                )}

                {!commonContext?.boxed && (
                  <button
                    type="button"
                    className={cvaMenuItem({ isDotHidden: true, isFontSize20: true })}
                    onClick={() => setShowPayments(true)}
                  >
                    <CreditCardIcon className={cvaIcon({ size: 'large' })} />
                    <span>
                      {!product_balance?.is_active && product_balance?.product
                        ? 'Upgrade plan'
                        : 'Manage plan'}
                    </span>
                  </button>
                )}
                <button
                  type="button"
                  className={cvaMenuItem({ isDotHidden: true, isFontSize20: true })}
                  onClick={() => setShowSettings(true)}
                >
                  <CogIcon className={cvaIcon({ size: 'large' })} />
                  <span>Settings</span>
                </button>
              </div>
              <hr className={cvaSeparator()} />
            </>
          )}
          <nav className={cvaNavMenuMobile()}>
            {(commonContext?.boxed ? navLinksV2 : navLinks).map(({ to, title, id }) => (
              <NavLink
                key={id}
                className={({ isActive }) =>
                  cvaMenuItem({
                    isActive,
                    isDotHidden: true,
                    isTransparent: false,
                    isFontSize20: true,
                  })
                }
                to={to}
                onClick={onClose}
              >
                {title}
              </NavLink>
            ))}
            {!commonContext?.boxed && (
              <>
                <a
                  href={route_path.blog}
                  className={cvaMenuItem({ isDotHidden: true, isFontSize20: true })}
                >
                  Blog
                </a>

                <Link
                  data-canny-link="header"
                  className={cvaMenuItem({ isDotHidden: true, isFontSize20: true })}
                  onClick={cannyLink}
                  to={CANNY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Suggest idea
                </Link>
              </>
            )}
          </nav>
          {isAuthenticated && (
            <>
              <hr className={cvaSeparator()} />
              <a
                href={route_path.logout}
                className={cvaMenuItem({ isDotHidden: true, isFontSize20: true })}
                onClick={onLogOut}
              >
                <LogoutIcon className={cvaIcon({ size: 'large' })} />
                <span>Log out</span>
              </a>
              {user_ctx?.is_superuser && (
                <Button size="large" variant="outline" asChild className="mt-8">
                  <a href={route_path.admin}>Admin</a>
                </Button>
              )}
            </>
          )}
          <Close className={cvaCloseButton()} onClick={onClose}>
            <CrossIcon className={cvaIcon({ size: 'large' })} />
          </Close>
        </div>
      </Content>
    </Portal>
  );
};
